/*
Theme Name: FDN2024
Theme URI: www.fdn.fr
Description: Thème pour le site de FDN de 2024
License: GNU General Public License v2 or later
License URI: http://www.gnu.org/licenses/gpl-2.0.html
Tags: FDN

Ressources :
- FDNone 2016 by quota_atypique http://www.hauteresolution.net
- MVP.css v1.15 - https://github.com/andybrewer/mvp
*/

/* Fontes */

@font-face {
  font-family: "Roboto";
  src: url('/assets/fonts/Roboto-Regular.ttf');
}
@font-face {
  font-family: "Roboto";
  font-style: italic;
  src: url('/assets/fonts/Roboto-Italic.ttf');
}
@font-face {
  font-family: "Roboto";
  font-weight: bold;
  src: url('/assets/fonts/Roboto-Bold.ttf');
}

/* root */

:root {
  --active-brightness: 0.85;
  --border-radius: 5px;
  --box-shadow: 2px 2px 10px;
  --color-accent: #f2f7ff;
  --color-bg2: #545864;
  --color-bg3: #444752;
  --color-bg4: #f9f2f4;
  --color-link: #337ab7;
  --color-over: #030303;
  --color-fdn1: #8da9d2;
  --color-fdn2: #d1e0f6;
  --color-fdn3: #f2f7ff;
  --color-fdn4: #c7254e;
  --color-fdn5: #5F7FAD;
  --color-shadow: #f4f4f4;
  --color-table: #d1e0f6;
  --color-primary: #030303;
  --color-secondary: #fdfdfd;
  --color-scrollbar: #cacae8;
  --font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  --font-large: 2em;
  --font-normal: 1em;
  --font-small: 0.85em;
  --hover-brightness: 1.2;
  --justify-important: center;
  --justify-normal: left;
  --justify-right: right;
  --line-height: 1.5;
  --width-card: 33%;
  --width-card-medium: 460px;
  --width-card-wide: 800px;
  --width-content: 1170px;
}

@media (prefers-color-scheme: dark) {
  :root[color-mode="user"] {
    --color-accent: #f2f7ff;
    --color-bg2: #545864;
    --color-bg3: #444752;
    --color-bg4: #f9f2f4;
    --color-link: #337ab7;
    --color-fdn1: #8da9d2;
    --color-fdn2: #d1e0f6;
    --color-fdn3: #f2f7ff;
    --color-fdn4: #c7254e;
    --color-fdn5: #5F7FAD;
    --color-shadow: #f4f4f4;
    --color-table: #d1e0f6;
    --color-primary: #fdfdfd;
    --color-secondary: #030303;
    --color-scrollbar: #cacae8;
  }
}

.site-header, .site-footer { 
  --color-secondary: #444752;
  --color-link: #fff;
  --color-primary: #fff;
}

.site-nav { 
  --color-secondary: #545864;
}