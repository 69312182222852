/* Bulma Grid */
:root {
    --bulma-column-gap: 0.75rem;
  }
  
  .column {
    display: block;
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
    padding: var(--bulma-column-gap);
  }
  .columns.is-mobile > .column.is-narrow {
    flex: none;
    width: unset;
  }
  .columns.is-mobile > .column.is-full {
    flex: none;
    width: 100%;
  }
  .columns.is-mobile > .column.is-three-quarters {
    flex: none;
    width: 75%;
  }
  .columns.is-mobile > .column.is-two-thirds {
    flex: none;
    width: 66.6666%;
  }
  .columns.is-mobile > .column.is-half {
    flex: none;
    width: 50%;
  }
  .columns.is-mobile > .column.is-one-third {
    flex: none;
    width: 33.3333%;
  }
  .columns.is-mobile > .column.is-one-quarter {
    flex: none;
    width: 25%;
  }
  .columns.is-mobile > .column.is-one-fifth {
    flex: none;
    width: 20%;
  }
  .columns.is-mobile > .column.is-two-fifths {
    flex: none;
    width: 40%;
  }
  .columns.is-mobile > .column.is-three-fifths {
    flex: none;
    width: 60%;
  }
  .columns.is-mobile > .column.is-four-fifths {
    flex: none;
    width: 80%;
  }
  .columns.is-mobile > .column.is-offset-three-quarters {
    margin-inline-start: 75%;
  }
  .columns.is-mobile > .column.is-offset-two-thirds {
    margin-inline-start: 66.6666%;
  }
  .columns.is-mobile > .column.is-offset-half {
    margin-inline-start: 50%;
  }
  .columns.is-mobile > .column.is-offset-one-third {
    margin-inline-start: 0.3333%;
  }
  .columns.is-mobile > .column.is-offset-one-quarter {
    margin-inline-start: 25%;
  }
  .columns.is-mobile > .column.is-offset-one-fifth {
    margin-inline-start: 20%;
  }
  .columns.is-mobile > .column.is-offset-two-fifths {
    margin-inline-start: 40%;
  }
  .columns.is-mobile > .column.is-offset-three-fifths {
    margin-inline-start: 60%;
  }
  .columns.is-mobile > .column.is-offset-four-fifths {
    margin-inline-start: 80%;
  }
  .columns.is-mobile > .column.is-0 {
    flex: none;
    width: 0%;
  }
  .columns.is-mobile > .column.is-offset-0 {
    margin-inline-start: 0%;
  }
  .columns.is-mobile > .column.is-1 {
    flex: none;
    width: 8.3333333333%;
  }
  .columns.is-mobile > .column.is-offset-1 {
    margin-inline-start: 8.3333333333%;
  }
  .columns.is-mobile > .column.is-2 {
    flex: none;
    width: 16.6666666667%;
  }
  .columns.is-mobile > .column.is-offset-2 {
    margin-inline-start: 16.6666666667%;
  }
  .columns.is-mobile > .column.is-3 {
    flex: none;
    width: 25%;
  }
  .columns.is-mobile > .column.is-offset-3 {
    margin-inline-start: 25%;
  }
  .columns.is-mobile > .column.is-4 {
    flex: none;
    width: 33.3333333333%;
  }
  .columns.is-mobile > .column.is-offset-4 {
    margin-inline-start: 33.3333333333%;
  }
  .columns.is-mobile > .column.is-5 {
    flex: none;
    width: 41.6666666667%;
  }
  .columns.is-mobile > .column.is-offset-5 {
    margin-inline-start: 41.6666666667%;
  }
  .columns.is-mobile > .column.is-6 {
    flex: none;
    width: 50%;
  }
  .columns.is-mobile > .column.is-offset-6 {
    margin-inline-start: 50%;
  }
  .columns.is-mobile > .column.is-7 {
    flex: none;
    width: 58.3333333333%;
  }
  .columns.is-mobile > .column.is-offset-7 {
    margin-inline-start: 58.3333333333%;
  }
  .columns.is-mobile > .column.is-8 {
    flex: none;
    width: 66.6666666667%;
  }
  .columns.is-mobile > .column.is-offset-8 {
    margin-inline-start: 66.6666666667%;
  }
  .columns.is-mobile > .column.is-9 {
    flex: none;
    width: 75%;
  }
  .columns.is-mobile > .column.is-offset-9 {
    margin-inline-start: 75%;
  }
  .columns.is-mobile > .column.is-10 {
    flex: none;
    width: 83.3333333333%;
  }
  .columns.is-mobile > .column.is-offset-10 {
    margin-inline-start: 83.3333333333%;
  }
  .columns.is-mobile > .column.is-11 {
    flex: none;
    width: 91.6666666667%;
  }
  .columns.is-mobile > .column.is-offset-11 {
    margin-inline-start: 91.6666666667%;
  }
  .columns.is-mobile > .column.is-12 {
    flex: none;
    width: 100%;
  }
  .columns.is-mobile > .column.is-offset-12 {
    margin-inline-start: 100%;
  }
  @media screen and (max-width: 768px) {
    .column.is-narrow-mobile {
      flex: none;
      width: unset;
    }
    .column.is-full-mobile {
      flex: none;
      width: 100%;
    }
    .column.is-three-quarters-mobile {
      flex: none;
      width: 75%;
    }
    .column.is-two-thirds-mobile {
      flex: none;
      width: 66.6666%;
    }
    .column.is-half-mobile {
      flex: none;
      width: 50%;
    }
    .column.is-one-third-mobile {
      flex: none;
      width: 33.3333%;
    }
    .column.is-one-quarter-mobile {
      flex: none;
      width: 25%;
    }
    .column.is-one-fifth-mobile {
      flex: none;
      width: 20%;
    }
    .column.is-two-fifths-mobile {
      flex: none;
      width: 40%;
    }
    .column.is-three-fifths-mobile {
      flex: none;
      width: 60%;
    }
    .column.is-four-fifths-mobile {
      flex: none;
      width: 80%;
    }
    .column.is-offset-three-quarters-mobile {
      margin-inline-start: 75%;
    }
    .column.is-offset-two-thirds-mobile {
      margin-inline-start: 66.6666%;
    }
    .column.is-offset-half-mobile {
      margin-inline-start: 50%;
    }
    .column.is-offset-one-third-mobile {
      margin-inline-start: 0.3333%;
    }
    .column.is-offset-one-quarter-mobile {
      margin-inline-start: 25%;
    }
    .column.is-offset-one-fifth-mobile {
      margin-inline-start: 20%;
    }
    .column.is-offset-two-fifths-mobile {
      margin-inline-start: 40%;
    }
    .column.is-offset-three-fifths-mobile {
      margin-inline-start: 60%;
    }
    .column.is-offset-four-fifths-mobile {
      margin-inline-start: 80%;
    }
    .column.is-0-mobile {
      flex: none;
      width: 0%;
    }
    .column.is-offset-0-mobile {
      margin-inline-start: 0%;
    }
    .column.is-1-mobile {
      flex: none;
      width: 8.3333333333%;
    }
    .column.is-offset-1-mobile {
      margin-inline-start: 8.3333333333%;
    }
    .column.is-2-mobile {
      flex: none;
      width: 16.6666666667%;
    }
    .column.is-offset-2-mobile {
      margin-inline-start: 16.6666666667%;
    }
    .column.is-3-mobile {
      flex: none;
      width: 25%;
    }
    .column.is-offset-3-mobile {
      margin-inline-start: 25%;
    }
    .column.is-4-mobile {
      flex: none;
      width: 33.3333333333%;
    }
    .column.is-offset-4-mobile {
      margin-inline-start: 33.3333333333%;
    }
    .column.is-5-mobile {
      flex: none;
      width: 41.6666666667%;
    }
    .column.is-offset-5-mobile {
      margin-inline-start: 41.6666666667%;
    }
    .column.is-6-mobile {
      flex: none;
      width: 50%;
    }
    .column.is-offset-6-mobile {
      margin-inline-start: 50%;
    }
    .column.is-7-mobile {
      flex: none;
      width: 58.3333333333%;
    }
    .column.is-offset-7-mobile {
      margin-inline-start: 58.3333333333%;
    }
    .column.is-8-mobile {
      flex: none;
      width: 66.6666666667%;
    }
    .column.is-offset-8-mobile {
      margin-inline-start: 66.6666666667%;
    }
    .column.is-9-mobile {
      flex: none;
      width: 75%;
    }
    .column.is-offset-9-mobile {
      margin-inline-start: 75%;
    }
    .column.is-10-mobile {
      flex: none;
      width: 83.3333333333%;
    }
    .column.is-offset-10-mobile {
      margin-inline-start: 83.3333333333%;
    }
    .column.is-11-mobile {
      flex: none;
      width: 91.6666666667%;
    }
    .column.is-offset-11-mobile {
      margin-inline-start: 91.6666666667%;
    }
    .column.is-12-mobile {
      flex: none;
      width: 100%;
    }
    .column.is-offset-12-mobile {
      margin-inline-start: 100%;
    }
  }
  @media screen and (min-width: 769px), print {
    .column.is-narrow, .column.is-narrow-tablet {
      flex: none;
      width: unset;
    }
    .column.is-full, .column.is-full-tablet {
      flex: none;
      width: 100%;
    }
    .column.is-three-quarters, .column.is-three-quarters-tablet {
      flex: none;
      width: 75%;
    }
    .column.is-two-thirds, .column.is-two-thirds-tablet {
      flex: none;
      width: 66.6666%;
    }
    .column.is-half, .column.is-half-tablet {
      flex: none;
      width: 50%;
    }
    .column.is-one-third, .column.is-one-third-tablet {
      flex: none;
      width: 33.3333%;
    }
    .column.is-one-quarter, .column.is-one-quarter-tablet {
      flex: none;
      width: 25%;
    }
    .column.is-one-fifth, .column.is-one-fifth-tablet {
      flex: none;
      width: 20%;
    }
    .column.is-two-fifths, .column.is-two-fifths-tablet {
      flex: none;
      width: 40%;
    }
    .column.is-three-fifths, .column.is-three-fifths-tablet {
      flex: none;
      width: 60%;
    }
    .column.is-four-fifths, .column.is-four-fifths-tablet {
      flex: none;
      width: 80%;
    }
    .column.is-offset-three-quarters, .column.is-offset-three-quarters-tablet {
      margin-inline-start: 75%;
    }
    .column.is-offset-two-thirds, .column.is-offset-two-thirds-tablet {
      margin-inline-start: 66.6666%;
    }
    .column.is-offset-half, .column.is-offset-half-tablet {
      margin-inline-start: 50%;
    }
    .column.is-offset-one-third, .column.is-offset-one-third-tablet {
      margin-inline-start: 0.3333%;
    }
    .column.is-offset-one-quarter, .column.is-offset-one-quarter-tablet {
      margin-inline-start: 25%;
    }
    .column.is-offset-one-fifth, .column.is-offset-one-fifth-tablet {
      margin-inline-start: 20%;
    }
    .column.is-offset-two-fifths, .column.is-offset-two-fifths-tablet {
      margin-inline-start: 40%;
    }
    .column.is-offset-three-fifths, .column.is-offset-three-fifths-tablet {
      margin-inline-start: 60%;
    }
    .column.is-offset-four-fifths, .column.is-offset-four-fifths-tablet {
      margin-inline-start: 80%;
    }
    .column.is-0, .column.is-0-tablet {
      flex: none;
      width: 0%;
    }
    .column.is-offset-0, .column.is-offset-0-tablet {
      margin-inline-start: 0%;
    }
    .column.is-1, .column.is-1-tablet {
      flex: none;
      width: 8.3333333333%;
    }
    .column.is-offset-1, .column.is-offset-1-tablet {
      margin-inline-start: 8.3333333333%;
    }
    .column.is-2, .column.is-2-tablet {
      flex: none;
      width: 16.6666666667%;
    }
    .column.is-offset-2, .column.is-offset-2-tablet {
      margin-inline-start: 16.6666666667%;
    }
    .column.is-3, .column.is-3-tablet {
      flex: none;
      width: 25%;
    }
    .column.is-offset-3, .column.is-offset-3-tablet {
      margin-inline-start: 25%;
    }
    .column.is-4, .column.is-4-tablet {
      flex: none;
      width: 33.3333333333%;
    }
    .column.is-offset-4, .column.is-offset-4-tablet {
      margin-inline-start: 33.3333333333%;
    }
    .column.is-5, .column.is-5-tablet {
      flex: none;
      width: 41.6666666667%;
    }
    .column.is-offset-5, .column.is-offset-5-tablet {
      margin-inline-start: 41.6666666667%;
    }
    .column.is-6, .column.is-6-tablet {
      flex: none;
      width: 50%;
    }
    .column.is-offset-6, .column.is-offset-6-tablet {
      margin-inline-start: 50%;
    }
    .column.is-7, .column.is-7-tablet {
      flex: none;
      width: 58.3333333333%;
    }
    .column.is-offset-7, .column.is-offset-7-tablet {
      margin-inline-start: 58.3333333333%;
    }
    .column.is-8, .column.is-8-tablet {
      flex: none;
      width: 66.6666666667%;
    }
    .column.is-offset-8, .column.is-offset-8-tablet {
      margin-inline-start: 66.6666666667%;
    }
    .column.is-9, .column.is-9-tablet {
      flex: none;
      width: 75%;
    }
    .column.is-offset-9, .column.is-offset-9-tablet {
      margin-inline-start: 75%;
    }
    .column.is-10, .column.is-10-tablet {
      flex: none;
      width: 83.3333333333%;
    }
    .column.is-offset-10, .column.is-offset-10-tablet {
      margin-inline-start: 83.3333333333%;
    }
    .column.is-11, .column.is-11-tablet {
      flex: none;
      width: 91.6666666667%;
    }
    .column.is-offset-11, .column.is-offset-11-tablet {
      margin-inline-start: 91.6666666667%;
    }
    .column.is-12, .column.is-12-tablet {
      flex: none;
      width: 100%;
    }
    .column.is-offset-12, .column.is-offset-12-tablet {
      margin-inline-start: 100%;
    }
  }
  @media screen and (max-width: 1023px) {
    .column.is-narrow-touch {
      flex: none;
      width: unset;
    }
    .column.is-full-touch {
      flex: none;
      width: 100%;
    }
    .column.is-three-quarters-touch {
      flex: none;
      width: 75%;
    }
    .column.is-two-thirds-touch {
      flex: none;
      width: 66.6666%;
    }
    .column.is-half-touch {
      flex: none;
      width: 50%;
    }
    .column.is-one-third-touch {
      flex: none;
      width: 33.3333%;
    }
    .column.is-one-quarter-touch {
      flex: none;
      width: 25%;
    }
    .column.is-one-fifth-touch {
      flex: none;
      width: 20%;
    }
    .column.is-two-fifths-touch {
      flex: none;
      width: 40%;
    }
    .column.is-three-fifths-touch {
      flex: none;
      width: 60%;
    }
    .column.is-four-fifths-touch {
      flex: none;
      width: 80%;
    }
    .column.is-offset-three-quarters-touch {
      margin-inline-start: 75%;
    }
    .column.is-offset-two-thirds-touch {
      margin-inline-start: 66.6666%;
    }
    .column.is-offset-half-touch {
      margin-inline-start: 50%;
    }
    .column.is-offset-one-third-touch {
      margin-inline-start: 0.3333%;
    }
    .column.is-offset-one-quarter-touch {
      margin-inline-start: 25%;
    }
    .column.is-offset-one-fifth-touch {
      margin-inline-start: 20%;
    }
    .column.is-offset-two-fifths-touch {
      margin-inline-start: 40%;
    }
    .column.is-offset-three-fifths-touch {
      margin-inline-start: 60%;
    }
    .column.is-offset-four-fifths-touch {
      margin-inline-start: 80%;
    }
    .column.is-0-touch {
      flex: none;
      width: 0%;
    }
    .column.is-offset-0-touch {
      margin-inline-start: 0%;
    }
    .column.is-1-touch {
      flex: none;
      width: 8.3333333333%;
    }
    .column.is-offset-1-touch {
      margin-inline-start: 8.3333333333%;
    }
    .column.is-2-touch {
      flex: none;
      width: 16.6666666667%;
    }
    .column.is-offset-2-touch {
      margin-inline-start: 16.6666666667%;
    }
    .column.is-3-touch {
      flex: none;
      width: 25%;
    }
    .column.is-offset-3-touch {
      margin-inline-start: 25%;
    }
    .column.is-4-touch {
      flex: none;
      width: 33.3333333333%;
    }
    .column.is-offset-4-touch {
      margin-inline-start: 33.3333333333%;
    }
    .column.is-5-touch {
      flex: none;
      width: 41.6666666667%;
    }
    .column.is-offset-5-touch {
      margin-inline-start: 41.6666666667%;
    }
    .column.is-6-touch {
      flex: none;
      width: 50%;
    }
    .column.is-offset-6-touch {
      margin-inline-start: 50%;
    }
    .column.is-7-touch {
      flex: none;
      width: 58.3333333333%;
    }
    .column.is-offset-7-touch {
      margin-inline-start: 58.3333333333%;
    }
    .column.is-8-touch {
      flex: none;
      width: 66.6666666667%;
    }
    .column.is-offset-8-touch {
      margin-inline-start: 66.6666666667%;
    }
    .column.is-9-touch {
      flex: none;
      width: 75%;
    }
    .column.is-offset-9-touch {
      margin-inline-start: 75%;
    }
    .column.is-10-touch {
      flex: none;
      width: 83.3333333333%;
    }
    .column.is-offset-10-touch {
      margin-inline-start: 83.3333333333%;
    }
    .column.is-11-touch {
      flex: none;
      width: 91.6666666667%;
    }
    .column.is-offset-11-touch {
      margin-inline-start: 91.6666666667%;
    }
    .column.is-12-touch {
      flex: none;
      width: 100%;
    }
    .column.is-offset-12-touch {
      margin-inline-start: 100%;
    }
  }
  @media screen and (min-width: 1024px) {
    .column.is-narrow-desktop {
      flex: none;
      width: unset;
    }
    .column.is-full-desktop {
      flex: none;
      width: 100%;
    }
    .column.is-three-quarters-desktop {
      flex: none;
      width: 75%;
    }
    .column.is-two-thirds-desktop {
      flex: none;
      width: 66.6666%;
    }
    .column.is-half-desktop {
      flex: none;
      width: 50%;
    }
    .column.is-one-third-desktop {
      flex: none;
      width: 33.3333%;
    }
    .column.is-one-quarter-desktop {
      flex: none;
      width: 25%;
    }
    .column.is-one-fifth-desktop {
      flex: none;
      width: 20%;
    }
    .column.is-two-fifths-desktop {
      flex: none;
      width: 40%;
    }
    .column.is-three-fifths-desktop {
      flex: none;
      width: 60%;
    }
    .column.is-four-fifths-desktop {
      flex: none;
      width: 80%;
    }
    .column.is-offset-three-quarters-desktop {
      margin-inline-start: 75%;
    }
    .column.is-offset-two-thirds-desktop {
      margin-inline-start: 66.6666%;
    }
    .column.is-offset-half-desktop {
      margin-inline-start: 50%;
    }
    .column.is-offset-one-third-desktop {
      margin-inline-start: 0.3333%;
    }
    .column.is-offset-one-quarter-desktop {
      margin-inline-start: 25%;
    }
    .column.is-offset-one-fifth-desktop {
      margin-inline-start: 20%;
    }
    .column.is-offset-two-fifths-desktop {
      margin-inline-start: 40%;
    }
    .column.is-offset-three-fifths-desktop {
      margin-inline-start: 60%;
    }
    .column.is-offset-four-fifths-desktop {
      margin-inline-start: 80%;
    }
    .column.is-0-desktop {
      flex: none;
      width: 0%;
    }
    .column.is-offset-0-desktop {
      margin-inline-start: 0%;
    }
    .column.is-1-desktop {
      flex: none;
      width: 8.3333333333%;
    }
    .column.is-offset-1-desktop {
      margin-inline-start: 8.3333333333%;
    }
    .column.is-2-desktop {
      flex: none;
      width: 16.6666666667%;
    }
    .column.is-offset-2-desktop {
      margin-inline-start: 16.6666666667%;
    }
    .column.is-3-desktop {
      flex: none;
      width: 25%;
    }
    .column.is-offset-3-desktop {
      margin-inline-start: 25%;
    }
    .column.is-4-desktop {
      flex: none;
      width: 33.3333333333%;
    }
    .column.is-offset-4-desktop {
      margin-inline-start: 33.3333333333%;
    }
    .column.is-5-desktop {
      flex: none;
      width: 41.6666666667%;
    }
    .column.is-offset-5-desktop {
      margin-inline-start: 41.6666666667%;
    }
    .column.is-6-desktop {
      flex: none;
      width: 50%;
    }
    .column.is-offset-6-desktop {
      margin-inline-start: 50%;
    }
    .column.is-7-desktop {
      flex: none;
      width: 58.3333333333%;
    }
    .column.is-offset-7-desktop {
      margin-inline-start: 58.3333333333%;
    }
    .column.is-8-desktop {
      flex: none;
      width: 66.6666666667%;
    }
    .column.is-offset-8-desktop {
      margin-inline-start: 66.6666666667%;
    }
    .column.is-9-desktop {
      flex: none;
      width: 75%;
    }
    .column.is-offset-9-desktop {
      margin-inline-start: 75%;
    }
    .column.is-10-desktop {
      flex: none;
      width: 83.3333333333%;
    }
    .column.is-offset-10-desktop {
      margin-inline-start: 83.3333333333%;
    }
    .column.is-11-desktop {
      flex: none;
      width: 91.6666666667%;
    }
    .column.is-offset-11-desktop {
      margin-inline-start: 91.6666666667%;
    }
    .column.is-12-desktop {
      flex: none;
      width: 100%;
    }
    .column.is-offset-12-desktop {
      margin-inline-start: 100%;
    }
  }
  @media screen and (min-width: 1216px) {
    .column.is-narrow-widescreen {
      flex: none;
      width: unset;
    }
    .column.is-full-widescreen {
      flex: none;
      width: 100%;
    }
    .column.is-three-quarters-widescreen {
      flex: none;
      width: 75%;
    }
    .column.is-two-thirds-widescreen {
      flex: none;
      width: 66.6666%;
    }
    .column.is-half-widescreen {
      flex: none;
      width: 50%;
    }
    .column.is-one-third-widescreen {
      flex: none;
      width: 33.3333%;
    }
    .column.is-one-quarter-widescreen {
      flex: none;
      width: 25%;
    }
    .column.is-one-fifth-widescreen {
      flex: none;
      width: 20%;
    }
    .column.is-two-fifths-widescreen {
      flex: none;
      width: 40%;
    }
    .column.is-three-fifths-widescreen {
      flex: none;
      width: 60%;
    }
    .column.is-four-fifths-widescreen {
      flex: none;
      width: 80%;
    }
    .column.is-offset-three-quarters-widescreen {
      margin-inline-start: 75%;
    }
    .column.is-offset-two-thirds-widescreen {
      margin-inline-start: 66.6666%;
    }
    .column.is-offset-half-widescreen {
      margin-inline-start: 50%;
    }
    .column.is-offset-one-third-widescreen {
      margin-inline-start: 0.3333%;
    }
    .column.is-offset-one-quarter-widescreen {
      margin-inline-start: 25%;
    }
    .column.is-offset-one-fifth-widescreen {
      margin-inline-start: 20%;
    }
    .column.is-offset-two-fifths-widescreen {
      margin-inline-start: 40%;
    }
    .column.is-offset-three-fifths-widescreen {
      margin-inline-start: 60%;
    }
    .column.is-offset-four-fifths-widescreen {
      margin-inline-start: 80%;
    }
    .column.is-0-widescreen {
      flex: none;
      width: 0%;
    }
    .column.is-offset-0-widescreen {
      margin-inline-start: 0%;
    }
    .column.is-1-widescreen {
      flex: none;
      width: 8.3333333333%;
    }
    .column.is-offset-1-widescreen {
      margin-inline-start: 8.3333333333%;
    }
    .column.is-2-widescreen {
      flex: none;
      width: 16.6666666667%;
    }
    .column.is-offset-2-widescreen {
      margin-inline-start: 16.6666666667%;
    }
    .column.is-3-widescreen {
      flex: none;
      width: 25%;
    }
    .column.is-offset-3-widescreen {
      margin-inline-start: 25%;
    }
    .column.is-4-widescreen {
      flex: none;
      width: 33.3333333333%;
    }
    .column.is-offset-4-widescreen {
      margin-inline-start: 33.3333333333%;
    }
    .column.is-5-widescreen {
      flex: none;
      width: 41.6666666667%;
    }
    .column.is-offset-5-widescreen {
      margin-inline-start: 41.6666666667%;
    }
    .column.is-6-widescreen {
      flex: none;
      width: 50%;
    }
    .column.is-offset-6-widescreen {
      margin-inline-start: 50%;
    }
    .column.is-7-widescreen {
      flex: none;
      width: 58.3333333333%;
    }
    .column.is-offset-7-widescreen {
      margin-inline-start: 58.3333333333%;
    }
    .column.is-8-widescreen {
      flex: none;
      width: 66.6666666667%;
    }
    .column.is-offset-8-widescreen {
      margin-inline-start: 66.6666666667%;
    }
    .column.is-9-widescreen {
      flex: none;
      width: 75%;
    }
    .column.is-offset-9-widescreen {
      margin-inline-start: 75%;
    }
    .column.is-10-widescreen {
      flex: none;
      width: 83.3333333333%;
    }
    .column.is-offset-10-widescreen {
      margin-inline-start: 83.3333333333%;
    }
    .column.is-11-widescreen {
      flex: none;
      width: 91.6666666667%;
    }
    .column.is-offset-11-widescreen {
      margin-inline-start: 91.6666666667%;
    }
    .column.is-12-widescreen {
      flex: none;
      width: 100%;
    }
    .column.is-offset-12-widescreen {
      margin-inline-start: 100%;
    }
  }
  @media screen and (min-width: 1408px) {
    .column.is-narrow-fullhd {
      flex: none;
      width: unset;
    }
    .column.is-full-fullhd {
      flex: none;
      width: 100%;
    }
    .column.is-three-quarters-fullhd {
      flex: none;
      width: 75%;
    }
    .column.is-two-thirds-fullhd {
      flex: none;
      width: 66.6666%;
    }
    .column.is-half-fullhd {
      flex: none;
      width: 50%;
    }
    .column.is-one-third-fullhd {
      flex: none;
      width: 33.3333%;
    }
    .column.is-one-quarter-fullhd {
      flex: none;
      width: 25%;
    }
    .column.is-one-fifth-fullhd {
      flex: none;
      width: 20%;
    }
    .column.is-two-fifths-fullhd {
      flex: none;
      width: 40%;
    }
    .column.is-three-fifths-fullhd {
      flex: none;
      width: 60%;
    }
    .column.is-four-fifths-fullhd {
      flex: none;
      width: 80%;
    }
    .column.is-offset-three-quarters-fullhd {
      margin-inline-start: 75%;
    }
    .column.is-offset-two-thirds-fullhd {
      margin-inline-start: 66.6666%;
    }
    .column.is-offset-half-fullhd {
      margin-inline-start: 50%;
    }
    .column.is-offset-one-third-fullhd {
      margin-inline-start: 33.3333%;
    }
    .column.is-offset-one-quarter-fullhd {
      margin-inline-start: 25%;
    }
    .column.is-offset-one-fifth-fullhd {
      margin-inline-start: 20%;
    }
    .column.is-offset-two-fifths-fullhd {
      margin-inline-start: 40%;
    }
    .column.is-offset-three-fifths-fullhd {
      margin-inline-start: 60%;
    }
    .column.is-offset-four-fifths-fullhd {
      margin-inline-start: 80%;
    }
    .column.is-0-fullhd {
      flex: none;
      width: 0%;
    }
    .column.is-offset-0-fullhd {
      margin-inline-start: 0%;
    }
    .column.is-1-fullhd {
      flex: none;
      width: 8.3333333333%;
    }
    .column.is-offset-1-fullhd {
      margin-inline-start: 8.3333333333%;
    }
    .column.is-2-fullhd {
      flex: none;
      width: 16.6666666667%;
    }
    .column.is-offset-2-fullhd {
      margin-inline-start: 16.6666666667%;
    }
    .column.is-3-fullhd {
      flex: none;
      width: 25%;
    }
    .column.is-offset-3-fullhd {
      margin-inline-start: 25%;
    }
    .column.is-4-fullhd {
      flex: none;
      width: 33.3333333333%;
    }
    .column.is-offset-4-fullhd {
      margin-inline-start: 33.3333333333%;
    }
    .column.is-5-fullhd {
      flex: none;
      width: 41.6666666667%;
    }
    .column.is-offset-5-fullhd {
      margin-inline-start: 41.6666666667%;
    }
    .column.is-6-fullhd {
      flex: none;
      width: 50%;
    }
    .column.is-offset-6-fullhd {
      margin-inline-start: 50%;
    }
    .column.is-7-fullhd {
      flex: none;
      width: 58.3333333333%;
    }
    .column.is-offset-7-fullhd {
      margin-inline-start: 58.3333333333%;
    }
    .column.is-8-fullhd {
      flex: none;
      width: 66.6666666667%;
    }
    .column.is-offset-8-fullhd {
      margin-inline-start: 66.6666666667%;
    }
    .column.is-9-fullhd {
      flex: none;
      width: 75%;
    }
    .column.is-offset-9-fullhd {
      margin-inline-start: 75%;
    }
    .column.is-10-fullhd {
      flex: none;
      width: 83.3333333333%;
    }
    .column.is-offset-10-fullhd {
      margin-inline-start: 83.3333333333%;
    }
    .column.is-11-fullhd {
      flex: none;
      width: 91.6666666667%;
    }
    .column.is-offset-11-fullhd {
      margin-inline-start: 91.6666666667%;
    }
    .column.is-12-fullhd {
      flex: none;
      width: 100%;
    }
    .column.is-offset-12-fullhd {
      margin-inline-start: 100%;
    }
  }
  
  .columns {
    margin-inline-start: calc(-1 * var(--bulma-column-gap));
    margin-inline-end: calc(-1 * var(--bulma-column-gap));
    margin-top: calc(-1 * var(--bulma-column-gap));
  }
  .columns:last-child {
    margin-bottom: calc(-1 * var(--bulma-column-gap));
  }
  .columns:not(:last-child) {
    margin-bottom: calc(var(--bulma-block-spacing) - var(--bulma-column-gap));
  }

  .columns.is-centered {
    justify-content: center;
  }
  .columns.is-gapless {
    margin-inline-start: 0;
    margin-inline-end: 0;
    margin-top: 0;
  }
  .columns.is-gapless > .column {
    margin: 0;
    padding: 0 !important;
  }
  .columns.is-gapless:not(:last-child) {
    margin-bottom: 1.5rem;
  }
  .columns.is-gapless:last-child {
    margin-bottom: 0;
  }
  .columns.is-mobile {
    display: flex;
  }
  .columns.is-multiline {
    flex-wrap: wrap;
  }
  .columns.is-vcentered {
    align-items: center;
  }
  @media screen and (min-width: 769px), print {
    .columns:not(.is-desktop) {
      display: flex;
    }
  }
  @media screen and (min-width: 1024px) {
    .columns.is-desktop {
      display: flex;
    }
  }
  .columns.is-0 {
    --bulma-column-gap: 0rem;
  }
  @media screen and (max-width: 768px) {
    .columns.is-0-mobile {
      --bulma-column-gap: 0rem;
    }
  }
  @media screen and (min-width: 769px), print {
    .columns.is-0-tablet {
      --bulma-column-gap: 0rem;
    }
  }
  @media screen and (min-width: 769px) and (max-width: 1023px) {
    .columns.is-0-tablet-only {
      --bulma-column-gap: 0rem;
    }
  }
  @media screen and (max-width: 1023px) {
    .columns.is-0-touch {
      --bulma-column-gap: 0rem;
    }
  }
  @media screen and (min-width: 1024px) {
    .columns.is-0-desktop {
      --bulma-column-gap: 0rem;
    }
  }
  @media screen and (min-width: 1024px) and (max-width: 1215px) {
    .columns.is-0-desktop-only {
      --bulma-column-gap: 0rem;
    }
  }
  @media screen and (min-width: 1216px) {
    .columns.is-0-widescreen {
      --bulma-column-gap: 0rem;
    }
  }
  @media screen and (min-width: 1216px) and (max-width: 1407px) {
    .columns.is-0-widescreen-only {
      --bulma-column-gap: 0rem;
    }
  }
  @media screen and (min-width: 1408px) {
    .columns.is-0-fullhd {
      --bulma-column-gap: 0rem;
    }
  }
  .columns.is-1 {
    --bulma-column-gap: 0.25rem;
  }
  @media screen and (max-width: 768px) {
    .columns.is-1-mobile {
      --bulma-column-gap: 0.25rem;
    }
  }
  @media screen and (min-width: 769px), print {
    .columns.is-1-tablet {
      --bulma-column-gap: 0.25rem;
    }
  }
  @media screen and (min-width: 769px) and (max-width: 1023px) {
    .columns.is-1-tablet-only {
      --bulma-column-gap: 0.25rem;
    }
  }
  @media screen and (max-width: 1023px) {
    .columns.is-1-touch {
      --bulma-column-gap: 0.25rem;
    }
  }
  @media screen and (min-width: 1024px) {
    .columns.is-1-desktop {
      --bulma-column-gap: 0.25rem;
    }
  }
  @media screen and (min-width: 1024px) and (max-width: 1215px) {
    .columns.is-1-desktop-only {
      --bulma-column-gap: 0.25rem;
    }
  }
  @media screen and (min-width: 1216px) {
    .columns.is-1-widescreen {
      --bulma-column-gap: 0.25rem;
    }
  }
  @media screen and (min-width: 1216px) and (max-width: 1407px) {
    .columns.is-1-widescreen-only {
      --bulma-column-gap: 0.25rem;
    }
  }
  @media screen and (min-width: 1408px) {
    .columns.is-1-fullhd {
      --bulma-column-gap: 0.25rem;
    }
  }
  .columns.is-2 {
    --bulma-column-gap: 0.5rem;
  }
  @media screen and (max-width: 768px) {
    .columns.is-2-mobile {
      --bulma-column-gap: 0.5rem;
    }
  }
  @media screen and (min-width: 769px), print {
    .columns.is-2-tablet {
      --bulma-column-gap: 0.5rem;
    }
  }
  @media screen and (min-width: 769px) and (max-width: 1023px) {
    .columns.is-2-tablet-only {
      --bulma-column-gap: 0.5rem;
    }
  }
  @media screen and (max-width: 1023px) {
    .columns.is-2-touch {
      --bulma-column-gap: 0.5rem;
    }
  }
  @media screen and (min-width: 1024px) {
    .columns.is-2-desktop {
      --bulma-column-gap: 0.5rem;
    }
  }
  @media screen and (min-width: 1024px) and (max-width: 1215px) {
    .columns.is-2-desktop-only {
      --bulma-column-gap: 0.5rem;
    }
  }
  @media screen and (min-width: 1216px) {
    .columns.is-2-widescreen {
      --bulma-column-gap: 0.5rem;
    }
  }
  @media screen and (min-width: 1216px) and (max-width: 1407px) {
    .columns.is-2-widescreen-only {
      --bulma-column-gap: 0.5rem;
    }
  }
  @media screen and (min-width: 1408px) {
    .columns.is-2-fullhd {
      --bulma-column-gap: 0.5rem;
    }
  }
  .columns.is-3 {
    --bulma-column-gap: 0.75rem;
  }
  @media screen and (max-width: 768px) {
    .columns.is-3-mobile {
      --bulma-column-gap: 0.75rem;
    }
  }
  @media screen and (min-width: 769px), print {
    .columns.is-3-tablet {
      --bulma-column-gap: 0.75rem;
    }
  }
  @media screen and (min-width: 769px) and (max-width: 1023px) {
    .columns.is-3-tablet-only {
      --bulma-column-gap: 0.75rem;
    }
  }
  @media screen and (max-width: 1023px) {
    .columns.is-3-touch {
      --bulma-column-gap: 0.75rem;
    }
  }
  @media screen and (min-width: 1024px) {
    .columns.is-3-desktop {
      --bulma-column-gap: 0.75rem;
    }
  }
  @media screen and (min-width: 1024px) and (max-width: 1215px) {
    .columns.is-3-desktop-only {
      --bulma-column-gap: 0.75rem;
    }
  }
  @media screen and (min-width: 1216px) {
    .columns.is-3-widescreen {
      --bulma-column-gap: 0.75rem;
    }
  }
  @media screen and (min-width: 1216px) and (max-width: 1407px) {
    .columns.is-3-widescreen-only {
      --bulma-column-gap: 0.75rem;
    }
  }
  @media screen and (min-width: 1408px) {
    .columns.is-3-fullhd {
      --bulma-column-gap: 0.75rem;
    }
  }
  .columns.is-4 {
    --bulma-column-gap: 1rem;
  }
  @media screen and (max-width: 768px) {
    .columns.is-4-mobile {
      --bulma-column-gap: 1rem;
    }
  }
  @media screen and (min-width: 769px), print {
    .columns.is-4-tablet {
      --bulma-column-gap: 1rem;
    }
  }
  @media screen and (min-width: 769px) and (max-width: 1023px) {
    .columns.is-4-tablet-only {
      --bulma-column-gap: 1rem;
    }
  }
  @media screen and (max-width: 1023px) {
    .columns.is-4-touch {
      --bulma-column-gap: 1rem;
    }
  }
  @media screen and (min-width: 1024px) {
    .columns.is-4-desktop {
      --bulma-column-gap: 1rem;
    }
  }
  @media screen and (min-width: 1024px) and (max-width: 1215px) {
    .columns.is-4-desktop-only {
      --bulma-column-gap: 1rem;
    }
  }
  @media screen and (min-width: 1216px) {
    .columns.is-4-widescreen {
      --bulma-column-gap: 1rem;
    }
  }
  @media screen and (min-width: 1216px) and (max-width: 1407px) {
    .columns.is-4-widescreen-only {
      --bulma-column-gap: 1rem;
    }
  }
  @media screen and (min-width: 1408px) {
    .columns.is-4-fullhd {
      --bulma-column-gap: 1rem;
    }
  }
  .columns.is-5 {
    --bulma-column-gap: 1.25rem;
  }
  @media screen and (max-width: 768px) {
    .columns.is-5-mobile {
      --bulma-column-gap: 1.25rem;
    }
  }
  @media screen and (min-width: 769px), print {
    .columns.is-5-tablet {
      --bulma-column-gap: 1.25rem;
    }
  }
  @media screen and (min-width: 769px) and (max-width: 1023px) {
    .columns.is-5-tablet-only {
      --bulma-column-gap: 1.25rem;
    }
  }
  @media screen and (max-width: 1023px) {
    .columns.is-5-touch {
      --bulma-column-gap: 1.25rem;
    }
  }
  @media screen and (min-width: 1024px) {
    .columns.is-5-desktop {
      --bulma-column-gap: 1.25rem;
    }
  }
  @media screen and (min-width: 1024px) and (max-width: 1215px) {
    .columns.is-5-desktop-only {
      --bulma-column-gap: 1.25rem;
    }
  }
  @media screen and (min-width: 1216px) {
    .columns.is-5-widescreen {
      --bulma-column-gap: 1.25rem;
    }
  }
  @media screen and (min-width: 1216px) and (max-width: 1407px) {
    .columns.is-5-widescreen-only {
      --bulma-column-gap: 1.25rem;
    }
  }
  @media screen and (min-width: 1408px) {
    .columns.is-5-fullhd {
      --bulma-column-gap: 1.25rem;
    }
  }
  .columns.is-6 {
    --bulma-column-gap: 1.5rem;
  }
  @media screen and (max-width: 768px) {
    .columns.is-6-mobile {
      --bulma-column-gap: 1.5rem;
    }
  }
  @media screen and (min-width: 769px), print {
    .columns.is-6-tablet {
      --bulma-column-gap: 1.5rem;
    }
  }
  @media screen and (min-width: 769px) and (max-width: 1023px) {
    .columns.is-6-tablet-only {
      --bulma-column-gap: 1.5rem;
    }
  }
  @media screen and (max-width: 1023px) {
    .columns.is-6-touch {
      --bulma-column-gap: 1.5rem;
    }
  }
  @media screen and (min-width: 1024px) {
    .columns.is-6-desktop {
      --bulma-column-gap: 1.5rem;
    }
  }
  @media screen and (min-width: 1024px) and (max-width: 1215px) {
    .columns.is-6-desktop-only {
      --bulma-column-gap: 1.5rem;
    }
  }
  @media screen and (min-width: 1216px) {
    .columns.is-6-widescreen {
      --bulma-column-gap: 1.5rem;
    }
  }
  @media screen and (min-width: 1216px) and (max-width: 1407px) {
    .columns.is-6-widescreen-only {
      --bulma-column-gap: 1.5rem;
    }
  }
  @media screen and (min-width: 1408px) {
    .columns.is-6-fullhd {
      --bulma-column-gap: 1.5rem;
    }
  }
  .columns.is-7 {
    --bulma-column-gap: 1.75rem;
  }
  @media screen and (max-width: 768px) {
    .columns.is-7-mobile {
      --bulma-column-gap: 1.75rem;
    }
  }
  @media screen and (min-width: 769px), print {
    .columns.is-7-tablet {
      --bulma-column-gap: 1.75rem;
    }
  }
  @media screen and (min-width: 769px) and (max-width: 1023px) {
    .columns.is-7-tablet-only {
      --bulma-column-gap: 1.75rem;
    }
  }
  @media screen and (max-width: 1023px) {
    .columns.is-7-touch {
      --bulma-column-gap: 1.75rem;
    }
  }
  @media screen and (min-width: 1024px) {
    .columns.is-7-desktop {
      --bulma-column-gap: 1.75rem;
    }
  }
  @media screen and (min-width: 1024px) and (max-width: 1215px) {
    .columns.is-7-desktop-only {
      --bulma-column-gap: 1.75rem;
    }
  }
  @media screen and (min-width: 1216px) {
    .columns.is-7-widescreen {
      --bulma-column-gap: 1.75rem;
    }
  }
  @media screen and (min-width: 1216px) and (max-width: 1407px) {
    .columns.is-7-widescreen-only {
      --bulma-column-gap: 1.75rem;
    }
  }
  @media screen and (min-width: 1408px) {
    .columns.is-7-fullhd {
      --bulma-column-gap: 1.75rem;
    }
  }
  .columns.is-8 {
    --bulma-column-gap: 2rem;
  }
  @media screen and (max-width: 768px) {
    .columns.is-8-mobile {
      --bulma-column-gap: 2rem;
    }
  }
  @media screen and (min-width: 769px), print {
    .columns.is-8-tablet {
      --bulma-column-gap: 2rem;
    }
  }
  @media screen and (min-width: 769px) and (max-width: 1023px) {
    .columns.is-8-tablet-only {
      --bulma-column-gap: 2rem;
    }
  }
  @media screen and (max-width: 1023px) {
    .columns.is-8-touch {
      --bulma-column-gap: 2rem;
    }
  }
  @media screen and (min-width: 1024px) {
    .columns.is-8-desktop {
      --bulma-column-gap: 2rem;
    }
  }
  @media screen and (min-width: 1024px) and (max-width: 1215px) {
    .columns.is-8-desktop-only {
      --bulma-column-gap: 2rem;
    }
  }
  @media screen and (min-width: 1216px) {
    .columns.is-8-widescreen {
      --bulma-column-gap: 2rem;
    }
  }
  @media screen and (min-width: 1216px) and (max-width: 1407px) {
    .columns.is-8-widescreen-only {
      --bulma-column-gap: 2rem;
    }
  }
  @media screen and (min-width: 1408px) {
    .columns.is-8-fullhd {
      --bulma-column-gap: 2rem;
    }
  }
  
  .fixed-grid {
    container-name: bulma-fixed-grid;
    container-type: inline-size;
  }
  .fixed-grid > .grid {
    --bulma-grid-gap-count: calc(var(--bulma-grid-column-count) - 1);
    --bulma-grid-column-count: 2;
    grid-template-columns: repeat(var(--bulma-grid-column-count), 1fr);
  }
  .fixed-grid.has-1-cols > .grid {
    --bulma-grid-column-count: 1;
  }
  .fixed-grid.has-2-cols > .grid {
    --bulma-grid-column-count: 2;
  }
  .fixed-grid.has-3-cols > .grid {
    --bulma-grid-column-count: 3;
  }
  .fixed-grid.has-4-cols > .grid {
    --bulma-grid-column-count: 4;
  }
  .fixed-grid.has-5-cols > .grid {
    --bulma-grid-column-count: 5;
  }
  .fixed-grid.has-6-cols > .grid {
    --bulma-grid-column-count: 6;
  }
  .fixed-grid.has-7-cols > .grid {
    --bulma-grid-column-count: 7;
  }
  .fixed-grid.has-8-cols > .grid {
    --bulma-grid-column-count: 8;
  }
  .fixed-grid.has-9-cols > .grid {
    --bulma-grid-column-count: 9;
  }
  .fixed-grid.has-10-cols > .grid {
    --bulma-grid-column-count: 10;
  }
  .fixed-grid.has-11-cols > .grid {
    --bulma-grid-column-count: 11;
  }
  .fixed-grid.has-12-cols > .grid {
    --bulma-grid-column-count: 12;
  }
  @container bulma-fixed-grid (max-width: 768px) {
    .fixed-grid.has-1-cols-mobile > .grid {
      --bulma-grid-column-count: 1;
    }
    .fixed-grid.has-2-cols-mobile > .grid {
      --bulma-grid-column-count: 2;
    }
    .fixed-grid.has-3-cols-mobile > .grid {
      --bulma-grid-column-count: 3;
    }
    .fixed-grid.has-4-cols-mobile > .grid {
      --bulma-grid-column-count: 4;
    }
    .fixed-grid.has-5-cols-mobile > .grid {
      --bulma-grid-column-count: 5;
    }
    .fixed-grid.has-6-cols-mobile > .grid {
      --bulma-grid-column-count: 6;
    }
    .fixed-grid.has-7-cols-mobile > .grid {
      --bulma-grid-column-count: 7;
    }
    .fixed-grid.has-8-cols-mobile > .grid {
      --bulma-grid-column-count: 8;
    }
    .fixed-grid.has-9-cols-mobile > .grid {
      --bulma-grid-column-count: 9;
    }
    .fixed-grid.has-10-cols-mobile > .grid {
      --bulma-grid-column-count: 10;
    }
    .fixed-grid.has-11-cols-mobile > .grid {
      --bulma-grid-column-count: 11;
    }
    .fixed-grid.has-12-cols-mobile > .grid {
      --bulma-grid-column-count: 12;
    }
  }
  @container bulma-fixed-grid (min-width: 769px) {
    .fixed-grid.has-1-cols-tablet > .grid {
      --bulma-grid-column-count: 1;
    }
    .fixed-grid.has-2-cols-tablet > .grid {
      --bulma-grid-column-count: 2;
    }
    .fixed-grid.has-3-cols-tablet > .grid {
      --bulma-grid-column-count: 3;
    }
    .fixed-grid.has-4-cols-tablet > .grid {
      --bulma-grid-column-count: 4;
    }
    .fixed-grid.has-5-cols-tablet > .grid {
      --bulma-grid-column-count: 5;
    }
    .fixed-grid.has-6-cols-tablet > .grid {
      --bulma-grid-column-count: 6;
    }
    .fixed-grid.has-7-cols-tablet > .grid {
      --bulma-grid-column-count: 7;
    }
    .fixed-grid.has-8-cols-tablet > .grid {
      --bulma-grid-column-count: 8;
    }
    .fixed-grid.has-9-cols-tablet > .grid {
      --bulma-grid-column-count: 9;
    }
    .fixed-grid.has-10-cols-tablet > .grid {
      --bulma-grid-column-count: 10;
    }
    .fixed-grid.has-11-cols-tablet > .grid {
      --bulma-grid-column-count: 11;
    }
    .fixed-grid.has-12-cols-tablet > .grid {
      --bulma-grid-column-count: 12;
    }
  }
  @container bulma-fixed-grid (min-width: 1024px) {
    .fixed-grid.has-1-cols-desktop > .grid {
      --bulma-grid-column-count: 1;
    }
    .fixed-grid.has-2-cols-desktop > .grid {
      --bulma-grid-column-count: 2;
    }
    .fixed-grid.has-3-cols-desktop > .grid {
      --bulma-grid-column-count: 3;
    }
    .fixed-grid.has-4-cols-desktop > .grid {
      --bulma-grid-column-count: 4;
    }
    .fixed-grid.has-5-cols-desktop > .grid {
      --bulma-grid-column-count: 5;
    }
    .fixed-grid.has-6-cols-desktop > .grid {
      --bulma-grid-column-count: 6;
    }
    .fixed-grid.has-7-cols-desktop > .grid {
      --bulma-grid-column-count: 7;
    }
    .fixed-grid.has-8-cols-desktop > .grid {
      --bulma-grid-column-count: 8;
    }
    .fixed-grid.has-9-cols-desktop > .grid {
      --bulma-grid-column-count: 9;
    }
    .fixed-grid.has-10-cols-desktop > .grid {
      --bulma-grid-column-count: 10;
    }
    .fixed-grid.has-11-cols-desktop > .grid {
      --bulma-grid-column-count: 11;
    }
    .fixed-grid.has-12-cols-desktop > .grid {
      --bulma-grid-column-count: 12;
    }
  }
  @container bulma-fixed-grid (min-width: 1216px) {
    .fixed-grid.has-1-cols-widescreen > .grid {
      --bulma-grid-column-count: 1;
    }
    .fixed-grid.has-2-cols-widescreen > .grid {
      --bulma-grid-column-count: 2;
    }
    .fixed-grid.has-3-cols-widescreen > .grid {
      --bulma-grid-column-count: 3;
    }
    .fixed-grid.has-4-cols-widescreen > .grid {
      --bulma-grid-column-count: 4;
    }
    .fixed-grid.has-5-cols-widescreen > .grid {
      --bulma-grid-column-count: 5;
    }
    .fixed-grid.has-6-cols-widescreen > .grid {
      --bulma-grid-column-count: 6;
    }
    .fixed-grid.has-7-cols-widescreen > .grid {
      --bulma-grid-column-count: 7;
    }
    .fixed-grid.has-8-cols-widescreen > .grid {
      --bulma-grid-column-count: 8;
    }
    .fixed-grid.has-9-cols-widescreen > .grid {
      --bulma-grid-column-count: 9;
    }
    .fixed-grid.has-10-cols-widescreen > .grid {
      --bulma-grid-column-count: 10;
    }
    .fixed-grid.has-11-cols-widescreen > .grid {
      --bulma-grid-column-count: 11;
    }
    .fixed-grid.has-12-cols-widescreen > .grid {
      --bulma-grid-column-count: 12;
    }
  }
  @container bulma-fixed-grid (min-width: 1408px) {
    .fixed-grid.has-1-cols-fullhd > .grid {
      --bulma-grid-column-count: 1;
    }
    .fixed-grid.has-2-cols-fullhd > .grid {
      --bulma-grid-column-count: 2;
    }
    .fixed-grid.has-3-cols-fullhd > .grid {
      --bulma-grid-column-count: 3;
    }
    .fixed-grid.has-4-cols-fullhd > .grid {
      --bulma-grid-column-count: 4;
    }
    .fixed-grid.has-5-cols-fullhd > .grid {
      --bulma-grid-column-count: 5;
    }
    .fixed-grid.has-6-cols-fullhd > .grid {
      --bulma-grid-column-count: 6;
    }
    .fixed-grid.has-7-cols-fullhd > .grid {
      --bulma-grid-column-count: 7;
    }
    .fixed-grid.has-8-cols-fullhd > .grid {
      --bulma-grid-column-count: 8;
    }
    .fixed-grid.has-9-cols-fullhd > .grid {
      --bulma-grid-column-count: 9;
    }
    .fixed-grid.has-10-cols-fullhd > .grid {
      --bulma-grid-column-count: 10;
    }
    .fixed-grid.has-11-cols-fullhd > .grid {
      --bulma-grid-column-count: 11;
    }
    .fixed-grid.has-12-cols-fullhd > .grid {
      --bulma-grid-column-count: 12;
    }
  }
  @container bulma-fixed-grid (max-width: 768px) {
    .fixed-grid.has-auto-count .grid {
      --bulma-grid-column-count: 2;
    }
  }
  @container bulma-fixed-grid (min-width: 769px) {
    .fixed-grid.has-auto-count .grid {
      --bulma-grid-column-count: 4;
    }
  }
  @container bulma-fixed-grid (min-width: 1024px) {
    .fixed-grid.has-auto-count .grid {
      --bulma-grid-column-count: 8;
    }
  }
  @container bulma-fixed-grid (min-width: 1216px) {
    .fixed-grid.has-auto-count .grid {
      --bulma-grid-column-count: 12;
    }
  }
  @container bulma-fixed-grid (min-width: 1408px) {
    .fixed-grid.has-auto-count .grid {
      --bulma-grid-column-count: 16;
    }
  }
  
  .grid {
    --bulma-grid-gap: 1rem;
    --bulma-grid-column-min: 10rem;
    --bulma-grid-cell-column-span: 1;
    --bulma-grid-cell-row-span: 1;
    display: grid;
    gap: var(--bulma-grid-gap);
    column-gap: var(--bulma-grid-column-gap, var(--bulma-grid-gap));
    row-gap: var(--bulma-grid-row-gap, var(--bulma-grid-gap));
    grid-template-columns: repeat(auto-fit, minmax(var(--bulma-grid-column-min), 1fr));
    grid-template-rows: auto;
  }
  .grid.is-auto-fill {
    grid-template-columns: repeat(auto-fill, minmax(var(--bulma-grid-column-min), 1fr));
  }
  .grid.is-col-min-1 {
    --bulma-grid-column-min: 1.5rem;
  }
  .grid.is-col-min-2 {
    --bulma-grid-column-min: 3rem;
  }
  .grid.is-col-min-3 {
    --bulma-grid-column-min: 4.5rem;
  }
  .grid.is-col-min-4 {
    --bulma-grid-column-min: 6rem;
  }
  .grid.is-col-min-5 {
    --bulma-grid-column-min: 7.5rem;
  }
  .grid.is-col-min-6 {
    --bulma-grid-column-min: 9rem;
  }
  .grid.is-col-min-7 {
    --bulma-grid-column-min: 10.5rem;
  }
  .grid.is-col-min-8 {
    --bulma-grid-column-min: 12rem;
  }
  .grid.is-col-min-9 {
    --bulma-grid-column-min: 13.5rem;
  }
  .grid.is-col-min-10 {
    --bulma-grid-column-min: 15rem;
  }
  .grid.is-col-min-11 {
    --bulma-grid-column-min: 16.5rem;
  }
  .grid.is-col-min-12 {
    --bulma-grid-column-min: 18rem;
  }
  .grid.is-col-min-13 {
    --bulma-grid-column-min: 19.5rem;
  }
  .grid.is-col-min-14 {
    --bulma-grid-column-min: 21rem;
  }
  .grid.is-col-min-15 {
    --bulma-grid-column-min: 22.5rem;
  }
  .grid.is-col-min-16 {
    --bulma-grid-column-min: 24rem;
  }
  .grid.is-col-min-17 {
    --bulma-grid-column-min: 25.5rem;
  }
  .grid.is-col-min-18 {
    --bulma-grid-column-min: 27rem;
  }
  .grid.is-col-min-19 {
    --bulma-grid-column-min: 28.5rem;
  }
  .grid.is-col-min-20 {
    --bulma-grid-column-min: 30rem;
  }
  .grid.is-col-min-21 {
    --bulma-grid-column-min: 31.5rem;
  }
  .grid.is-col-min-22 {
    --bulma-grid-column-min: 33rem;
  }
  .grid.is-col-min-23 {
    --bulma-grid-column-min: 34.5rem;
  }
  .grid.is-col-min-24 {
    --bulma-grid-column-min: 36rem;
  }
  .grid.is-col-min-25 {
    --bulma-grid-column-min: 37.5rem;
  }
  .grid.is-col-min-26 {
    --bulma-grid-column-min: 39rem;
  }
  .grid.is-col-min-27 {
    --bulma-grid-column-min: 40.5rem;
  }
  .grid.is-col-min-28 {
    --bulma-grid-column-min: 42rem;
  }
  .grid.is-col-min-29 {
    --bulma-grid-column-min: 43.5rem;
  }
  .grid.is-col-min-30 {
    --bulma-grid-column-min: 45rem;
  }
  .grid.is-col-min-31 {
    --bulma-grid-column-min: 46.5rem;
  }
  .grid.is-col-min-32 {
    --bulma-grid-column-min: 48rem;
  }
  
  .cell {
    grid-column-end: span var(--bulma-grid-cell-column-span);
    grid-column-start: var(--bulma-grid-cell-column-start);
    grid-row-end: span var(--bulma-grid-cell-row-span);
    grid-row-start: var(--bulma-grid-cell-row-start);
  }
  .cell.is-col-start-end {
    --bulma-grid-cell-column-start: -1;
  }
  .cell.is-row-start-end {
    --bulma-grid-cell-row-start: -1;
  }
  .cell.is-col-start-1 {
    --bulma-grid-cell-column-start: 1;
  }
  .cell.is-col-end-1 {
    --bulma-grid-cell-column-end: 1;
  }
  .cell.is-col-from-end-1 {
    --bulma-grid-cell-column-start: -1;
  }
  .cell.is-col-span-1 {
    --bulma-grid-cell-column-span: 1;
  }
  .cell.is-row-start-1 {
    --bulma-grid-cell-row-start: 1;
  }
  .cell.is-row-end-1 {
    --bulma-grid-cell-row-end: 1;
  }
  .cell.is-row-from-end-1 {
    --bulma-grid-cell-row-start: -1;
  }
  .cell.is-row-span-1 {
    --bulma-grid-cell-row-span: 1;
  }
  .cell.is-col-start-2 {
    --bulma-grid-cell-column-start: 2;
  }
  .cell.is-col-end-2 {
    --bulma-grid-cell-column-end: 2;
  }
  .cell.is-col-from-end-2 {
    --bulma-grid-cell-column-start: -2;
  }
  .cell.is-col-span-2 {
    --bulma-grid-cell-column-span: 2;
  }
  .cell.is-row-start-2 {
    --bulma-grid-cell-row-start: 2;
  }
  .cell.is-row-end-2 {
    --bulma-grid-cell-row-end: 2;
  }
  .cell.is-row-from-end-2 {
    --bulma-grid-cell-row-start: -2;
  }
  .cell.is-row-span-2 {
    --bulma-grid-cell-row-span: 2;
  }
  .cell.is-col-start-3 {
    --bulma-grid-cell-column-start: 3;
  }
  .cell.is-col-end-3 {
    --bulma-grid-cell-column-end: 3;
  }
  .cell.is-col-from-end-3 {
    --bulma-grid-cell-column-start: -3;
  }
  .cell.is-col-span-3 {
    --bulma-grid-cell-column-span: 3;
  }
  .cell.is-row-start-3 {
    --bulma-grid-cell-row-start: 3;
  }
  .cell.is-row-end-3 {
    --bulma-grid-cell-row-end: 3;
  }
  .cell.is-row-from-end-3 {
    --bulma-grid-cell-row-start: -3;
  }
  .cell.is-row-span-3 {
    --bulma-grid-cell-row-span: 3;
  }
  .cell.is-col-start-4 {
    --bulma-grid-cell-column-start: 4;
  }
  .cell.is-col-end-4 {
    --bulma-grid-cell-column-end: 4;
  }
  .cell.is-col-from-end-4 {
    --bulma-grid-cell-column-start: -4;
  }
  .cell.is-col-span-4 {
    --bulma-grid-cell-column-span: 4;
  }
  .cell.is-row-start-4 {
    --bulma-grid-cell-row-start: 4;
  }
  .cell.is-row-end-4 {
    --bulma-grid-cell-row-end: 4;
  }
  .cell.is-row-from-end-4 {
    --bulma-grid-cell-row-start: -4;
  }
  .cell.is-row-span-4 {
    --bulma-grid-cell-row-span: 4;
  }
  .cell.is-col-start-5 {
    --bulma-grid-cell-column-start: 5;
  }
  .cell.is-col-end-5 {
    --bulma-grid-cell-column-end: 5;
  }
  .cell.is-col-from-end-5 {
    --bulma-grid-cell-column-start: -5;
  }
  .cell.is-col-span-5 {
    --bulma-grid-cell-column-span: 5;
  }
  .cell.is-row-start-5 {
    --bulma-grid-cell-row-start: 5;
  }
  .cell.is-row-end-5 {
    --bulma-grid-cell-row-end: 5;
  }
  .cell.is-row-from-end-5 {
    --bulma-grid-cell-row-start: -5;
  }
  .cell.is-row-span-5 {
    --bulma-grid-cell-row-span: 5;
  }
  .cell.is-col-start-6 {
    --bulma-grid-cell-column-start: 6;
  }
  .cell.is-col-end-6 {
    --bulma-grid-cell-column-end: 6;
  }
  .cell.is-col-from-end-6 {
    --bulma-grid-cell-column-start: -6;
  }
  .cell.is-col-span-6 {
    --bulma-grid-cell-column-span: 6;
  }
  .cell.is-row-start-6 {
    --bulma-grid-cell-row-start: 6;
  }
  .cell.is-row-end-6 {
    --bulma-grid-cell-row-end: 6;
  }
  .cell.is-row-from-end-6 {
    --bulma-grid-cell-row-start: -6;
  }
  .cell.is-row-span-6 {
    --bulma-grid-cell-row-span: 6;
  }
  .cell.is-col-start-7 {
    --bulma-grid-cell-column-start: 7;
  }
  .cell.is-col-end-7 {
    --bulma-grid-cell-column-end: 7;
  }
  .cell.is-col-from-end-7 {
    --bulma-grid-cell-column-start: -7;
  }
  .cell.is-col-span-7 {
    --bulma-grid-cell-column-span: 7;
  }
  .cell.is-row-start-7 {
    --bulma-grid-cell-row-start: 7;
  }
  .cell.is-row-end-7 {
    --bulma-grid-cell-row-end: 7;
  }
  .cell.is-row-from-end-7 {
    --bulma-grid-cell-row-start: -7;
  }
  .cell.is-row-span-7 {
    --bulma-grid-cell-row-span: 7;
  }
  .cell.is-col-start-8 {
    --bulma-grid-cell-column-start: 8;
  }
  .cell.is-col-end-8 {
    --bulma-grid-cell-column-end: 8;
  }
  .cell.is-col-from-end-8 {
    --bulma-grid-cell-column-start: -8;
  }
  .cell.is-col-span-8 {
    --bulma-grid-cell-column-span: 8;
  }
  .cell.is-row-start-8 {
    --bulma-grid-cell-row-start: 8;
  }
  .cell.is-row-end-8 {
    --bulma-grid-cell-row-end: 8;
  }
  .cell.is-row-from-end-8 {
    --bulma-grid-cell-row-start: -8;
  }
  .cell.is-row-span-8 {
    --bulma-grid-cell-row-span: 8;
  }
  .cell.is-col-start-9 {
    --bulma-grid-cell-column-start: 9;
  }
  .cell.is-col-end-9 {
    --bulma-grid-cell-column-end: 9;
  }
  .cell.is-col-from-end-9 {
    --bulma-grid-cell-column-start: -9;
  }
  .cell.is-col-span-9 {
    --bulma-grid-cell-column-span: 9;
  }
  .cell.is-row-start-9 {
    --bulma-grid-cell-row-start: 9;
  }
  .cell.is-row-end-9 {
    --bulma-grid-cell-row-end: 9;
  }
  .cell.is-row-from-end-9 {
    --bulma-grid-cell-row-start: -9;
  }
  .cell.is-row-span-9 {
    --bulma-grid-cell-row-span: 9;
  }
  .cell.is-col-start-10 {
    --bulma-grid-cell-column-start: 10;
  }
  .cell.is-col-end-10 {
    --bulma-grid-cell-column-end: 10;
  }
  .cell.is-col-from-end-10 {
    --bulma-grid-cell-column-start: -10;
  }
  .cell.is-col-span-10 {
    --bulma-grid-cell-column-span: 10;
  }
  .cell.is-row-start-10 {
    --bulma-grid-cell-row-start: 10;
  }
  .cell.is-row-end-10 {
    --bulma-grid-cell-row-end: 10;
  }
  .cell.is-row-from-end-10 {
    --bulma-grid-cell-row-start: -10;
  }
  .cell.is-row-span-10 {
    --bulma-grid-cell-row-span: 10;
  }
  .cell.is-col-start-11 {
    --bulma-grid-cell-column-start: 11;
  }
  .cell.is-col-end-11 {
    --bulma-grid-cell-column-end: 11;
  }
  .cell.is-col-from-end-11 {
    --bulma-grid-cell-column-start: -11;
  }
  .cell.is-col-span-11 {
    --bulma-grid-cell-column-span: 11;
  }
  .cell.is-row-start-11 {
    --bulma-grid-cell-row-start: 11;
  }
  .cell.is-row-end-11 {
    --bulma-grid-cell-row-end: 11;
  }
  .cell.is-row-from-end-11 {
    --bulma-grid-cell-row-start: -11;
  }
  .cell.is-row-span-11 {
    --bulma-grid-cell-row-span: 11;
  }
  .cell.is-col-start-12 {
    --bulma-grid-cell-column-start: 12;
  }
  .cell.is-col-end-12 {
    --bulma-grid-cell-column-end: 12;
  }
  .cell.is-col-from-end-12 {
    --bulma-grid-cell-column-start: -12;
  }
  .cell.is-col-span-12 {
    --bulma-grid-cell-column-span: 12;
  }
  .cell.is-row-start-12 {
    --bulma-grid-cell-row-start: 12;
  }
  .cell.is-row-end-12 {
    --bulma-grid-cell-row-end: 12;
  }
  .cell.is-row-from-end-12 {
    --bulma-grid-cell-row-start: -12;
  }
  .cell.is-row-span-12 {
    --bulma-grid-cell-row-span: 12;
  }
  @media screen and (max-width: 768px) {
    .cell.is-col-start-1-mobile {
      --bulma-grid-cell-column-start: 1;
    }
    .cell.is-col-end-1-mobile {
      --bulma-grid-cell-column-end: 1;
    }
    .cell.is-col-from-end-1-mobile {
      --bulma-grid-cell-column-start: -1;
    }
    .cell.is-col-span-1-mobile {
      --bulma-grid-cell-column-span: 1;
    }
    .cell.is-row-start-1-mobile {
      --bulma-grid-cell-row-start: 1;
    }
    .cell.is-row-end-1-mobile {
      --bulma-grid-cell-row-end: 1;
    }
    .cell.is-row-from-end-1-mobile {
      --bulma-grid-cell-row-start: -1;
    }
    .cell.is-row-span-1-mobile {
      --bulma-grid-cell-row-span: 1;
    }
    .cell.is-col-start-2-mobile {
      --bulma-grid-cell-column-start: 2;
    }
    .cell.is-col-end-2-mobile {
      --bulma-grid-cell-column-end: 2;
    }
    .cell.is-col-from-end-2-mobile {
      --bulma-grid-cell-column-start: -2;
    }
    .cell.is-col-span-2-mobile {
      --bulma-grid-cell-column-span: 2;
    }
    .cell.is-row-start-2-mobile {
      --bulma-grid-cell-row-start: 2;
    }
    .cell.is-row-end-2-mobile {
      --bulma-grid-cell-row-end: 2;
    }
    .cell.is-row-from-end-2-mobile {
      --bulma-grid-cell-row-start: -2;
    }
    .cell.is-row-span-2-mobile {
      --bulma-grid-cell-row-span: 2;
    }
    .cell.is-col-start-3-mobile {
      --bulma-grid-cell-column-start: 3;
    }
    .cell.is-col-end-3-mobile {
      --bulma-grid-cell-column-end: 3;
    }
    .cell.is-col-from-end-3-mobile {
      --bulma-grid-cell-column-start: -3;
    }
    .cell.is-col-span-3-mobile {
      --bulma-grid-cell-column-span: 3;
    }
    .cell.is-row-start-3-mobile {
      --bulma-grid-cell-row-start: 3;
    }
    .cell.is-row-end-3-mobile {
      --bulma-grid-cell-row-end: 3;
    }
    .cell.is-row-from-end-3-mobile {
      --bulma-grid-cell-row-start: -3;
    }
    .cell.is-row-span-3-mobile {
      --bulma-grid-cell-row-span: 3;
    }
    .cell.is-col-start-4-mobile {
      --bulma-grid-cell-column-start: 4;
    }
    .cell.is-col-end-4-mobile {
      --bulma-grid-cell-column-end: 4;
    }
    .cell.is-col-from-end-4-mobile {
      --bulma-grid-cell-column-start: -4;
    }
    .cell.is-col-span-4-mobile {
      --bulma-grid-cell-column-span: 4;
    }
    .cell.is-row-start-4-mobile {
      --bulma-grid-cell-row-start: 4;
    }
    .cell.is-row-end-4-mobile {
      --bulma-grid-cell-row-end: 4;
    }
    .cell.is-row-from-end-4-mobile {
      --bulma-grid-cell-row-start: -4;
    }
    .cell.is-row-span-4-mobile {
      --bulma-grid-cell-row-span: 4;
    }
    .cell.is-col-start-5-mobile {
      --bulma-grid-cell-column-start: 5;
    }
    .cell.is-col-end-5-mobile {
      --bulma-grid-cell-column-end: 5;
    }
    .cell.is-col-from-end-5-mobile {
      --bulma-grid-cell-column-start: -5;
    }
    .cell.is-col-span-5-mobile {
      --bulma-grid-cell-column-span: 5;
    }
    .cell.is-row-start-5-mobile {
      --bulma-grid-cell-row-start: 5;
    }
    .cell.is-row-end-5-mobile {
      --bulma-grid-cell-row-end: 5;
    }
    .cell.is-row-from-end-5-mobile {
      --bulma-grid-cell-row-start: -5;
    }
    .cell.is-row-span-5-mobile {
      --bulma-grid-cell-row-span: 5;
    }
    .cell.is-col-start-6-mobile {
      --bulma-grid-cell-column-start: 6;
    }
    .cell.is-col-end-6-mobile {
      --bulma-grid-cell-column-end: 6;
    }
    .cell.is-col-from-end-6-mobile {
      --bulma-grid-cell-column-start: -6;
    }
    .cell.is-col-span-6-mobile {
      --bulma-grid-cell-column-span: 6;
    }
    .cell.is-row-start-6-mobile {
      --bulma-grid-cell-row-start: 6;
    }
    .cell.is-row-end-6-mobile {
      --bulma-grid-cell-row-end: 6;
    }
    .cell.is-row-from-end-6-mobile {
      --bulma-grid-cell-row-start: -6;
    }
    .cell.is-row-span-6-mobile {
      --bulma-grid-cell-row-span: 6;
    }
    .cell.is-col-start-7-mobile {
      --bulma-grid-cell-column-start: 7;
    }
    .cell.is-col-end-7-mobile {
      --bulma-grid-cell-column-end: 7;
    }
    .cell.is-col-from-end-7-mobile {
      --bulma-grid-cell-column-start: -7;
    }
    .cell.is-col-span-7-mobile {
      --bulma-grid-cell-column-span: 7;
    }
    .cell.is-row-start-7-mobile {
      --bulma-grid-cell-row-start: 7;
    }
    .cell.is-row-end-7-mobile {
      --bulma-grid-cell-row-end: 7;
    }
    .cell.is-row-from-end-7-mobile {
      --bulma-grid-cell-row-start: -7;
    }
    .cell.is-row-span-7-mobile {
      --bulma-grid-cell-row-span: 7;
    }
    .cell.is-col-start-8-mobile {
      --bulma-grid-cell-column-start: 8;
    }
    .cell.is-col-end-8-mobile {
      --bulma-grid-cell-column-end: 8;
    }
    .cell.is-col-from-end-8-mobile {
      --bulma-grid-cell-column-start: -8;
    }
    .cell.is-col-span-8-mobile {
      --bulma-grid-cell-column-span: 8;
    }
    .cell.is-row-start-8-mobile {
      --bulma-grid-cell-row-start: 8;
    }
    .cell.is-row-end-8-mobile {
      --bulma-grid-cell-row-end: 8;
    }
    .cell.is-row-from-end-8-mobile {
      --bulma-grid-cell-row-start: -8;
    }
    .cell.is-row-span-8-mobile {
      --bulma-grid-cell-row-span: 8;
    }
    .cell.is-col-start-9-mobile {
      --bulma-grid-cell-column-start: 9;
    }
    .cell.is-col-end-9-mobile {
      --bulma-grid-cell-column-end: 9;
    }
    .cell.is-col-from-end-9-mobile {
      --bulma-grid-cell-column-start: -9;
    }
    .cell.is-col-span-9-mobile {
      --bulma-grid-cell-column-span: 9;
    }
    .cell.is-row-start-9-mobile {
      --bulma-grid-cell-row-start: 9;
    }
    .cell.is-row-end-9-mobile {
      --bulma-grid-cell-row-end: 9;
    }
    .cell.is-row-from-end-9-mobile {
      --bulma-grid-cell-row-start: -9;
    }
    .cell.is-row-span-9-mobile {
      --bulma-grid-cell-row-span: 9;
    }
    .cell.is-col-start-10-mobile {
      --bulma-grid-cell-column-start: 10;
    }
    .cell.is-col-end-10-mobile {
      --bulma-grid-cell-column-end: 10;
    }
    .cell.is-col-from-end-10-mobile {
      --bulma-grid-cell-column-start: -10;
    }
    .cell.is-col-span-10-mobile {
      --bulma-grid-cell-column-span: 10;
    }
    .cell.is-row-start-10-mobile {
      --bulma-grid-cell-row-start: 10;
    }
    .cell.is-row-end-10-mobile {
      --bulma-grid-cell-row-end: 10;
    }
    .cell.is-row-from-end-10-mobile {
      --bulma-grid-cell-row-start: -10;
    }
    .cell.is-row-span-10-mobile {
      --bulma-grid-cell-row-span: 10;
    }
    .cell.is-col-start-11-mobile {
      --bulma-grid-cell-column-start: 11;
    }
    .cell.is-col-end-11-mobile {
      --bulma-grid-cell-column-end: 11;
    }
    .cell.is-col-from-end-11-mobile {
      --bulma-grid-cell-column-start: -11;
    }
    .cell.is-col-span-11-mobile {
      --bulma-grid-cell-column-span: 11;
    }
    .cell.is-row-start-11-mobile {
      --bulma-grid-cell-row-start: 11;
    }
    .cell.is-row-end-11-mobile {
      --bulma-grid-cell-row-end: 11;
    }
    .cell.is-row-from-end-11-mobile {
      --bulma-grid-cell-row-start: -11;
    }
    .cell.is-row-span-11-mobile {
      --bulma-grid-cell-row-span: 11;
    }
    .cell.is-col-start-12-mobile {
      --bulma-grid-cell-column-start: 12;
    }
    .cell.is-col-end-12-mobile {
      --bulma-grid-cell-column-end: 12;
    }
    .cell.is-col-from-end-12-mobile {
      --bulma-grid-cell-column-start: -12;
    }
    .cell.is-col-span-12-mobile {
      --bulma-grid-cell-column-span: 12;
    }
    .cell.is-row-start-12-mobile {
      --bulma-grid-cell-row-start: 12;
    }
    .cell.is-row-end-12-mobile {
      --bulma-grid-cell-row-end: 12;
    }
    .cell.is-row-from-end-12-mobile {
      --bulma-grid-cell-row-start: -12;
    }
    .cell.is-row-span-12-mobile {
      --bulma-grid-cell-row-span: 12;
    }
  }
  @media screen and (min-width: 769px), print {
    .cell.is-col-start-1-tablet {
      --bulma-grid-cell-column-start: 1;
    }
    .cell.is-col-end-1-tablet {
      --bulma-grid-cell-column-end: 1;
    }
    .cell.is-col-from-end-1-tablet {
      --bulma-grid-cell-column-start: -1;
    }
    .cell.is-col-span-1-tablet {
      --bulma-grid-cell-column-span: 1;
    }
    .cell.is-row-start-1-tablet {
      --bulma-grid-cell-row-start: 1;
    }
    .cell.is-row-end-1-tablet {
      --bulma-grid-cell-row-end: 1;
    }
    .cell.is-row-from-end-1-tablet {
      --bulma-grid-cell-row-start: -1;
    }
    .cell.is-row-span-1-tablet {
      --bulma-grid-cell-row-span: 1;
    }
    .cell.is-col-start-2-tablet {
      --bulma-grid-cell-column-start: 2;
    }
    .cell.is-col-end-2-tablet {
      --bulma-grid-cell-column-end: 2;
    }
    .cell.is-col-from-end-2-tablet {
      --bulma-grid-cell-column-start: -2;
    }
    .cell.is-col-span-2-tablet {
      --bulma-grid-cell-column-span: 2;
    }
    .cell.is-row-start-2-tablet {
      --bulma-grid-cell-row-start: 2;
    }
    .cell.is-row-end-2-tablet {
      --bulma-grid-cell-row-end: 2;
    }
    .cell.is-row-from-end-2-tablet {
      --bulma-grid-cell-row-start: -2;
    }
    .cell.is-row-span-2-tablet {
      --bulma-grid-cell-row-span: 2;
    }
    .cell.is-col-start-3-tablet {
      --bulma-grid-cell-column-start: 3;
    }
    .cell.is-col-end-3-tablet {
      --bulma-grid-cell-column-end: 3;
    }
    .cell.is-col-from-end-3-tablet {
      --bulma-grid-cell-column-start: -3;
    }
    .cell.is-col-span-3-tablet {
      --bulma-grid-cell-column-span: 3;
    }
    .cell.is-row-start-3-tablet {
      --bulma-grid-cell-row-start: 3;
    }
    .cell.is-row-end-3-tablet {
      --bulma-grid-cell-row-end: 3;
    }
    .cell.is-row-from-end-3-tablet {
      --bulma-grid-cell-row-start: -3;
    }
    .cell.is-row-span-3-tablet {
      --bulma-grid-cell-row-span: 3;
    }
    .cell.is-col-start-4-tablet {
      --bulma-grid-cell-column-start: 4;
    }
    .cell.is-col-end-4-tablet {
      --bulma-grid-cell-column-end: 4;
    }
    .cell.is-col-from-end-4-tablet {
      --bulma-grid-cell-column-start: -4;
    }
    .cell.is-col-span-4-tablet {
      --bulma-grid-cell-column-span: 4;
    }
    .cell.is-row-start-4-tablet {
      --bulma-grid-cell-row-start: 4;
    }
    .cell.is-row-end-4-tablet {
      --bulma-grid-cell-row-end: 4;
    }
    .cell.is-row-from-end-4-tablet {
      --bulma-grid-cell-row-start: -4;
    }
    .cell.is-row-span-4-tablet {
      --bulma-grid-cell-row-span: 4;
    }
    .cell.is-col-start-5-tablet {
      --bulma-grid-cell-column-start: 5;
    }
    .cell.is-col-end-5-tablet {
      --bulma-grid-cell-column-end: 5;
    }
    .cell.is-col-from-end-5-tablet {
      --bulma-grid-cell-column-start: -5;
    }
    .cell.is-col-span-5-tablet {
      --bulma-grid-cell-column-span: 5;
    }
    .cell.is-row-start-5-tablet {
      --bulma-grid-cell-row-start: 5;
    }
    .cell.is-row-end-5-tablet {
      --bulma-grid-cell-row-end: 5;
    }
    .cell.is-row-from-end-5-tablet {
      --bulma-grid-cell-row-start: -5;
    }
    .cell.is-row-span-5-tablet {
      --bulma-grid-cell-row-span: 5;
    }
    .cell.is-col-start-6-tablet {
      --bulma-grid-cell-column-start: 6;
    }
    .cell.is-col-end-6-tablet {
      --bulma-grid-cell-column-end: 6;
    }
    .cell.is-col-from-end-6-tablet {
      --bulma-grid-cell-column-start: -6;
    }
    .cell.is-col-span-6-tablet {
      --bulma-grid-cell-column-span: 6;
    }
    .cell.is-row-start-6-tablet {
      --bulma-grid-cell-row-start: 6;
    }
    .cell.is-row-end-6-tablet {
      --bulma-grid-cell-row-end: 6;
    }
    .cell.is-row-from-end-6-tablet {
      --bulma-grid-cell-row-start: -6;
    }
    .cell.is-row-span-6-tablet {
      --bulma-grid-cell-row-span: 6;
    }
    .cell.is-col-start-7-tablet {
      --bulma-grid-cell-column-start: 7;
    }
    .cell.is-col-end-7-tablet {
      --bulma-grid-cell-column-end: 7;
    }
    .cell.is-col-from-end-7-tablet {
      --bulma-grid-cell-column-start: -7;
    }
    .cell.is-col-span-7-tablet {
      --bulma-grid-cell-column-span: 7;
    }
    .cell.is-row-start-7-tablet {
      --bulma-grid-cell-row-start: 7;
    }
    .cell.is-row-end-7-tablet {
      --bulma-grid-cell-row-end: 7;
    }
    .cell.is-row-from-end-7-tablet {
      --bulma-grid-cell-row-start: -7;
    }
    .cell.is-row-span-7-tablet {
      --bulma-grid-cell-row-span: 7;
    }
    .cell.is-col-start-8-tablet {
      --bulma-grid-cell-column-start: 8;
    }
    .cell.is-col-end-8-tablet {
      --bulma-grid-cell-column-end: 8;
    }
    .cell.is-col-from-end-8-tablet {
      --bulma-grid-cell-column-start: -8;
    }
    .cell.is-col-span-8-tablet {
      --bulma-grid-cell-column-span: 8;
    }
    .cell.is-row-start-8-tablet {
      --bulma-grid-cell-row-start: 8;
    }
    .cell.is-row-end-8-tablet {
      --bulma-grid-cell-row-end: 8;
    }
    .cell.is-row-from-end-8-tablet {
      --bulma-grid-cell-row-start: -8;
    }
    .cell.is-row-span-8-tablet {
      --bulma-grid-cell-row-span: 8;
    }
    .cell.is-col-start-9-tablet {
      --bulma-grid-cell-column-start: 9;
    }
    .cell.is-col-end-9-tablet {
      --bulma-grid-cell-column-end: 9;
    }
    .cell.is-col-from-end-9-tablet {
      --bulma-grid-cell-column-start: -9;
    }
    .cell.is-col-span-9-tablet {
      --bulma-grid-cell-column-span: 9;
    }
    .cell.is-row-start-9-tablet {
      --bulma-grid-cell-row-start: 9;
    }
    .cell.is-row-end-9-tablet {
      --bulma-grid-cell-row-end: 9;
    }
    .cell.is-row-from-end-9-tablet {
      --bulma-grid-cell-row-start: -9;
    }
    .cell.is-row-span-9-tablet {
      --bulma-grid-cell-row-span: 9;
    }
    .cell.is-col-start-10-tablet {
      --bulma-grid-cell-column-start: 10;
    }
    .cell.is-col-end-10-tablet {
      --bulma-grid-cell-column-end: 10;
    }
    .cell.is-col-from-end-10-tablet {
      --bulma-grid-cell-column-start: -10;
    }
    .cell.is-col-span-10-tablet {
      --bulma-grid-cell-column-span: 10;
    }
    .cell.is-row-start-10-tablet {
      --bulma-grid-cell-row-start: 10;
    }
    .cell.is-row-end-10-tablet {
      --bulma-grid-cell-row-end: 10;
    }
    .cell.is-row-from-end-10-tablet {
      --bulma-grid-cell-row-start: -10;
    }
    .cell.is-row-span-10-tablet {
      --bulma-grid-cell-row-span: 10;
    }
    .cell.is-col-start-11-tablet {
      --bulma-grid-cell-column-start: 11;
    }
    .cell.is-col-end-11-tablet {
      --bulma-grid-cell-column-end: 11;
    }
    .cell.is-col-from-end-11-tablet {
      --bulma-grid-cell-column-start: -11;
    }
    .cell.is-col-span-11-tablet {
      --bulma-grid-cell-column-span: 11;
    }
    .cell.is-row-start-11-tablet {
      --bulma-grid-cell-row-start: 11;
    }
    .cell.is-row-end-11-tablet {
      --bulma-grid-cell-row-end: 11;
    }
    .cell.is-row-from-end-11-tablet {
      --bulma-grid-cell-row-start: -11;
    }
    .cell.is-row-span-11-tablet {
      --bulma-grid-cell-row-span: 11;
    }
    .cell.is-col-start-12-tablet {
      --bulma-grid-cell-column-start: 12;
    }
    .cell.is-col-end-12-tablet {
      --bulma-grid-cell-column-end: 12;
    }
    .cell.is-col-from-end-12-tablet {
      --bulma-grid-cell-column-start: -12;
    }
    .cell.is-col-span-12-tablet {
      --bulma-grid-cell-column-span: 12;
    }
    .cell.is-row-start-12-tablet {
      --bulma-grid-cell-row-start: 12;
    }
    .cell.is-row-end-12-tablet {
      --bulma-grid-cell-row-end: 12;
    }
    .cell.is-row-from-end-12-tablet {
      --bulma-grid-cell-row-start: -12;
    }
    .cell.is-row-span-12-tablet {
      --bulma-grid-cell-row-span: 12;
    }
  }
  @media screen and (min-width: 769px) and (max-width: 1023px) {
    .cell.is-col-start-1-tablet-only {
      --bulma-grid-cell-column-start: 1;
    }
    .cell.is-col-end-1-tablet-only {
      --bulma-grid-cell-column-end: 1;
    }
    .cell.is-col-from-end-1-tablet-only {
      --bulma-grid-cell-column-start: -1;
    }
    .cell.is-col-span-1-tablet-only {
      --bulma-grid-cell-column-span: 1;
    }
    .cell.is-row-start-1-tablet-only {
      --bulma-grid-cell-row-start: 1;
    }
    .cell.is-row-end-1-tablet-only {
      --bulma-grid-cell-row-end: 1;
    }
    .cell.is-row-from-end-1-tablet-only {
      --bulma-grid-cell-row-start: -1;
    }
    .cell.is-row-span-1-tablet-only {
      --bulma-grid-cell-row-span: 1;
    }
    .cell.is-col-start-2-tablet-only {
      --bulma-grid-cell-column-start: 2;
    }
    .cell.is-col-end-2-tablet-only {
      --bulma-grid-cell-column-end: 2;
    }
    .cell.is-col-from-end-2-tablet-only {
      --bulma-grid-cell-column-start: -2;
    }
    .cell.is-col-span-2-tablet-only {
      --bulma-grid-cell-column-span: 2;
    }
    .cell.is-row-start-2-tablet-only {
      --bulma-grid-cell-row-start: 2;
    }
    .cell.is-row-end-2-tablet-only {
      --bulma-grid-cell-row-end: 2;
    }
    .cell.is-row-from-end-2-tablet-only {
      --bulma-grid-cell-row-start: -2;
    }
    .cell.is-row-span-2-tablet-only {
      --bulma-grid-cell-row-span: 2;
    }
    .cell.is-col-start-3-tablet-only {
      --bulma-grid-cell-column-start: 3;
    }
    .cell.is-col-end-3-tablet-only {
      --bulma-grid-cell-column-end: 3;
    }
    .cell.is-col-from-end-3-tablet-only {
      --bulma-grid-cell-column-start: -3;
    }
    .cell.is-col-span-3-tablet-only {
      --bulma-grid-cell-column-span: 3;
    }
    .cell.is-row-start-3-tablet-only {
      --bulma-grid-cell-row-start: 3;
    }
    .cell.is-row-end-3-tablet-only {
      --bulma-grid-cell-row-end: 3;
    }
    .cell.is-row-from-end-3-tablet-only {
      --bulma-grid-cell-row-start: -3;
    }
    .cell.is-row-span-3-tablet-only {
      --bulma-grid-cell-row-span: 3;
    }
    .cell.is-col-start-4-tablet-only {
      --bulma-grid-cell-column-start: 4;
    }
    .cell.is-col-end-4-tablet-only {
      --bulma-grid-cell-column-end: 4;
    }
    .cell.is-col-from-end-4-tablet-only {
      --bulma-grid-cell-column-start: -4;
    }
    .cell.is-col-span-4-tablet-only {
      --bulma-grid-cell-column-span: 4;
    }
    .cell.is-row-start-4-tablet-only {
      --bulma-grid-cell-row-start: 4;
    }
    .cell.is-row-end-4-tablet-only {
      --bulma-grid-cell-row-end: 4;
    }
    .cell.is-row-from-end-4-tablet-only {
      --bulma-grid-cell-row-start: -4;
    }
    .cell.is-row-span-4-tablet-only {
      --bulma-grid-cell-row-span: 4;
    }
    .cell.is-col-start-5-tablet-only {
      --bulma-grid-cell-column-start: 5;
    }
    .cell.is-col-end-5-tablet-only {
      --bulma-grid-cell-column-end: 5;
    }
    .cell.is-col-from-end-5-tablet-only {
      --bulma-grid-cell-column-start: -5;
    }
    .cell.is-col-span-5-tablet-only {
      --bulma-grid-cell-column-span: 5;
    }
    .cell.is-row-start-5-tablet-only {
      --bulma-grid-cell-row-start: 5;
    }
    .cell.is-row-end-5-tablet-only {
      --bulma-grid-cell-row-end: 5;
    }
    .cell.is-row-from-end-5-tablet-only {
      --bulma-grid-cell-row-start: -5;
    }
    .cell.is-row-span-5-tablet-only {
      --bulma-grid-cell-row-span: 5;
    }
    .cell.is-col-start-6-tablet-only {
      --bulma-grid-cell-column-start: 6;
    }
    .cell.is-col-end-6-tablet-only {
      --bulma-grid-cell-column-end: 6;
    }
    .cell.is-col-from-end-6-tablet-only {
      --bulma-grid-cell-column-start: -6;
    }
    .cell.is-col-span-6-tablet-only {
      --bulma-grid-cell-column-span: 6;
    }
    .cell.is-row-start-6-tablet-only {
      --bulma-grid-cell-row-start: 6;
    }
    .cell.is-row-end-6-tablet-only {
      --bulma-grid-cell-row-end: 6;
    }
    .cell.is-row-from-end-6-tablet-only {
      --bulma-grid-cell-row-start: -6;
    }
    .cell.is-row-span-6-tablet-only {
      --bulma-grid-cell-row-span: 6;
    }
    .cell.is-col-start-7-tablet-only {
      --bulma-grid-cell-column-start: 7;
    }
    .cell.is-col-end-7-tablet-only {
      --bulma-grid-cell-column-end: 7;
    }
    .cell.is-col-from-end-7-tablet-only {
      --bulma-grid-cell-column-start: -7;
    }
    .cell.is-col-span-7-tablet-only {
      --bulma-grid-cell-column-span: 7;
    }
    .cell.is-row-start-7-tablet-only {
      --bulma-grid-cell-row-start: 7;
    }
    .cell.is-row-end-7-tablet-only {
      --bulma-grid-cell-row-end: 7;
    }
    .cell.is-row-from-end-7-tablet-only {
      --bulma-grid-cell-row-start: -7;
    }
    .cell.is-row-span-7-tablet-only {
      --bulma-grid-cell-row-span: 7;
    }
    .cell.is-col-start-8-tablet-only {
      --bulma-grid-cell-column-start: 8;
    }
    .cell.is-col-end-8-tablet-only {
      --bulma-grid-cell-column-end: 8;
    }
    .cell.is-col-from-end-8-tablet-only {
      --bulma-grid-cell-column-start: -8;
    }
    .cell.is-col-span-8-tablet-only {
      --bulma-grid-cell-column-span: 8;
    }
    .cell.is-row-start-8-tablet-only {
      --bulma-grid-cell-row-start: 8;
    }
    .cell.is-row-end-8-tablet-only {
      --bulma-grid-cell-row-end: 8;
    }
    .cell.is-row-from-end-8-tablet-only {
      --bulma-grid-cell-row-start: -8;
    }
    .cell.is-row-span-8-tablet-only {
      --bulma-grid-cell-row-span: 8;
    }
    .cell.is-col-start-9-tablet-only {
      --bulma-grid-cell-column-start: 9;
    }
    .cell.is-col-end-9-tablet-only {
      --bulma-grid-cell-column-end: 9;
    }
    .cell.is-col-from-end-9-tablet-only {
      --bulma-grid-cell-column-start: -9;
    }
    .cell.is-col-span-9-tablet-only {
      --bulma-grid-cell-column-span: 9;
    }
    .cell.is-row-start-9-tablet-only {
      --bulma-grid-cell-row-start: 9;
    }
    .cell.is-row-end-9-tablet-only {
      --bulma-grid-cell-row-end: 9;
    }
    .cell.is-row-from-end-9-tablet-only {
      --bulma-grid-cell-row-start: -9;
    }
    .cell.is-row-span-9-tablet-only {
      --bulma-grid-cell-row-span: 9;
    }
    .cell.is-col-start-10-tablet-only {
      --bulma-grid-cell-column-start: 10;
    }
    .cell.is-col-end-10-tablet-only {
      --bulma-grid-cell-column-end: 10;
    }
    .cell.is-col-from-end-10-tablet-only {
      --bulma-grid-cell-column-start: -10;
    }
    .cell.is-col-span-10-tablet-only {
      --bulma-grid-cell-column-span: 10;
    }
    .cell.is-row-start-10-tablet-only {
      --bulma-grid-cell-row-start: 10;
    }
    .cell.is-row-end-10-tablet-only {
      --bulma-grid-cell-row-end: 10;
    }
    .cell.is-row-from-end-10-tablet-only {
      --bulma-grid-cell-row-start: -10;
    }
    .cell.is-row-span-10-tablet-only {
      --bulma-grid-cell-row-span: 10;
    }
    .cell.is-col-start-11-tablet-only {
      --bulma-grid-cell-column-start: 11;
    }
    .cell.is-col-end-11-tablet-only {
      --bulma-grid-cell-column-end: 11;
    }
    .cell.is-col-from-end-11-tablet-only {
      --bulma-grid-cell-column-start: -11;
    }
    .cell.is-col-span-11-tablet-only {
      --bulma-grid-cell-column-span: 11;
    }
    .cell.is-row-start-11-tablet-only {
      --bulma-grid-cell-row-start: 11;
    }
    .cell.is-row-end-11-tablet-only {
      --bulma-grid-cell-row-end: 11;
    }
    .cell.is-row-from-end-11-tablet-only {
      --bulma-grid-cell-row-start: -11;
    }
    .cell.is-row-span-11-tablet-only {
      --bulma-grid-cell-row-span: 11;
    }
    .cell.is-col-start-12-tablet-only {
      --bulma-grid-cell-column-start: 12;
    }
    .cell.is-col-end-12-tablet-only {
      --bulma-grid-cell-column-end: 12;
    }
    .cell.is-col-from-end-12-tablet-only {
      --bulma-grid-cell-column-start: -12;
    }
    .cell.is-col-span-12-tablet-only {
      --bulma-grid-cell-column-span: 12;
    }
    .cell.is-row-start-12-tablet-only {
      --bulma-grid-cell-row-start: 12;
    }
    .cell.is-row-end-12-tablet-only {
      --bulma-grid-cell-row-end: 12;
    }
    .cell.is-row-from-end-12-tablet-only {
      --bulma-grid-cell-row-start: -12;
    }
    .cell.is-row-span-12-tablet-only {
      --bulma-grid-cell-row-span: 12;
    }
  }
  @media screen and (min-width: 1024px) {
    .cell.is-col-start-1-desktop {
      --bulma-grid-cell-column-start: 1;
    }
    .cell.is-col-end-1-desktop {
      --bulma-grid-cell-column-end: 1;
    }
    .cell.is-col-from-end-1-desktop {
      --bulma-grid-cell-column-start: -1;
    }
    .cell.is-col-span-1-desktop {
      --bulma-grid-cell-column-span: 1;
    }
    .cell.is-row-start-1-desktop {
      --bulma-grid-cell-row-start: 1;
    }
    .cell.is-row-end-1-desktop {
      --bulma-grid-cell-row-end: 1;
    }
    .cell.is-row-from-end-1-desktop {
      --bulma-grid-cell-row-start: -1;
    }
    .cell.is-row-span-1-desktop {
      --bulma-grid-cell-row-span: 1;
    }
    .cell.is-col-start-2-desktop {
      --bulma-grid-cell-column-start: 2;
    }
    .cell.is-col-end-2-desktop {
      --bulma-grid-cell-column-end: 2;
    }
    .cell.is-col-from-end-2-desktop {
      --bulma-grid-cell-column-start: -2;
    }
    .cell.is-col-span-2-desktop {
      --bulma-grid-cell-column-span: 2;
    }
    .cell.is-row-start-2-desktop {
      --bulma-grid-cell-row-start: 2;
    }
    .cell.is-row-end-2-desktop {
      --bulma-grid-cell-row-end: 2;
    }
    .cell.is-row-from-end-2-desktop {
      --bulma-grid-cell-row-start: -2;
    }
    .cell.is-row-span-2-desktop {
      --bulma-grid-cell-row-span: 2;
    }
    .cell.is-col-start-3-desktop {
      --bulma-grid-cell-column-start: 3;
    }
    .cell.is-col-end-3-desktop {
      --bulma-grid-cell-column-end: 3;
    }
    .cell.is-col-from-end-3-desktop {
      --bulma-grid-cell-column-start: -3;
    }
    .cell.is-col-span-3-desktop {
      --bulma-grid-cell-column-span: 3;
    }
    .cell.is-row-start-3-desktop {
      --bulma-grid-cell-row-start: 3;
    }
    .cell.is-row-end-3-desktop {
      --bulma-grid-cell-row-end: 3;
    }
    .cell.is-row-from-end-3-desktop {
      --bulma-grid-cell-row-start: -3;
    }
    .cell.is-row-span-3-desktop {
      --bulma-grid-cell-row-span: 3;
    }
    .cell.is-col-start-4-desktop {
      --bulma-grid-cell-column-start: 4;
    }
    .cell.is-col-end-4-desktop {
      --bulma-grid-cell-column-end: 4;
    }
    .cell.is-col-from-end-4-desktop {
      --bulma-grid-cell-column-start: -4;
    }
    .cell.is-col-span-4-desktop {
      --bulma-grid-cell-column-span: 4;
    }
    .cell.is-row-start-4-desktop {
      --bulma-grid-cell-row-start: 4;
    }
    .cell.is-row-end-4-desktop {
      --bulma-grid-cell-row-end: 4;
    }
    .cell.is-row-from-end-4-desktop {
      --bulma-grid-cell-row-start: -4;
    }
    .cell.is-row-span-4-desktop {
      --bulma-grid-cell-row-span: 4;
    }
    .cell.is-col-start-5-desktop {
      --bulma-grid-cell-column-start: 5;
    }
    .cell.is-col-end-5-desktop {
      --bulma-grid-cell-column-end: 5;
    }
    .cell.is-col-from-end-5-desktop {
      --bulma-grid-cell-column-start: -5;
    }
    .cell.is-col-span-5-desktop {
      --bulma-grid-cell-column-span: 5;
    }
    .cell.is-row-start-5-desktop {
      --bulma-grid-cell-row-start: 5;
    }
    .cell.is-row-end-5-desktop {
      --bulma-grid-cell-row-end: 5;
    }
    .cell.is-row-from-end-5-desktop {
      --bulma-grid-cell-row-start: -5;
    }
    .cell.is-row-span-5-desktop {
      --bulma-grid-cell-row-span: 5;
    }
    .cell.is-col-start-6-desktop {
      --bulma-grid-cell-column-start: 6;
    }
    .cell.is-col-end-6-desktop {
      --bulma-grid-cell-column-end: 6;
    }
    .cell.is-col-from-end-6-desktop {
      --bulma-grid-cell-column-start: -6;
    }
    .cell.is-col-span-6-desktop {
      --bulma-grid-cell-column-span: 6;
    }
    .cell.is-row-start-6-desktop {
      --bulma-grid-cell-row-start: 6;
    }
    .cell.is-row-end-6-desktop {
      --bulma-grid-cell-row-end: 6;
    }
    .cell.is-row-from-end-6-desktop {
      --bulma-grid-cell-row-start: -6;
    }
    .cell.is-row-span-6-desktop {
      --bulma-grid-cell-row-span: 6;
    }
    .cell.is-col-start-7-desktop {
      --bulma-grid-cell-column-start: 7;
    }
    .cell.is-col-end-7-desktop {
      --bulma-grid-cell-column-end: 7;
    }
    .cell.is-col-from-end-7-desktop {
      --bulma-grid-cell-column-start: -7;
    }
    .cell.is-col-span-7-desktop {
      --bulma-grid-cell-column-span: 7;
    }
    .cell.is-row-start-7-desktop {
      --bulma-grid-cell-row-start: 7;
    }
    .cell.is-row-end-7-desktop {
      --bulma-grid-cell-row-end: 7;
    }
    .cell.is-row-from-end-7-desktop {
      --bulma-grid-cell-row-start: -7;
    }
    .cell.is-row-span-7-desktop {
      --bulma-grid-cell-row-span: 7;
    }
    .cell.is-col-start-8-desktop {
      --bulma-grid-cell-column-start: 8;
    }
    .cell.is-col-end-8-desktop {
      --bulma-grid-cell-column-end: 8;
    }
    .cell.is-col-from-end-8-desktop {
      --bulma-grid-cell-column-start: -8;
    }
    .cell.is-col-span-8-desktop {
      --bulma-grid-cell-column-span: 8;
    }
    .cell.is-row-start-8-desktop {
      --bulma-grid-cell-row-start: 8;
    }
    .cell.is-row-end-8-desktop {
      --bulma-grid-cell-row-end: 8;
    }
    .cell.is-row-from-end-8-desktop {
      --bulma-grid-cell-row-start: -8;
    }
    .cell.is-row-span-8-desktop {
      --bulma-grid-cell-row-span: 8;
    }
    .cell.is-col-start-9-desktop {
      --bulma-grid-cell-column-start: 9;
    }
    .cell.is-col-end-9-desktop {
      --bulma-grid-cell-column-end: 9;
    }
    .cell.is-col-from-end-9-desktop {
      --bulma-grid-cell-column-start: -9;
    }
    .cell.is-col-span-9-desktop {
      --bulma-grid-cell-column-span: 9;
    }
    .cell.is-row-start-9-desktop {
      --bulma-grid-cell-row-start: 9;
    }
    .cell.is-row-end-9-desktop {
      --bulma-grid-cell-row-end: 9;
    }
    .cell.is-row-from-end-9-desktop {
      --bulma-grid-cell-row-start: -9;
    }
    .cell.is-row-span-9-desktop {
      --bulma-grid-cell-row-span: 9;
    }
    .cell.is-col-start-10-desktop {
      --bulma-grid-cell-column-start: 10;
    }
    .cell.is-col-end-10-desktop {
      --bulma-grid-cell-column-end: 10;
    }
    .cell.is-col-from-end-10-desktop {
      --bulma-grid-cell-column-start: -10;
    }
    .cell.is-col-span-10-desktop {
      --bulma-grid-cell-column-span: 10;
    }
    .cell.is-row-start-10-desktop {
      --bulma-grid-cell-row-start: 10;
    }
    .cell.is-row-end-10-desktop {
      --bulma-grid-cell-row-end: 10;
    }
    .cell.is-row-from-end-10-desktop {
      --bulma-grid-cell-row-start: -10;
    }
    .cell.is-row-span-10-desktop {
      --bulma-grid-cell-row-span: 10;
    }
    .cell.is-col-start-11-desktop {
      --bulma-grid-cell-column-start: 11;
    }
    .cell.is-col-end-11-desktop {
      --bulma-grid-cell-column-end: 11;
    }
    .cell.is-col-from-end-11-desktop {
      --bulma-grid-cell-column-start: -11;
    }
    .cell.is-col-span-11-desktop {
      --bulma-grid-cell-column-span: 11;
    }
    .cell.is-row-start-11-desktop {
      --bulma-grid-cell-row-start: 11;
    }
    .cell.is-row-end-11-desktop {
      --bulma-grid-cell-row-end: 11;
    }
    .cell.is-row-from-end-11-desktop {
      --bulma-grid-cell-row-start: -11;
    }
    .cell.is-row-span-11-desktop {
      --bulma-grid-cell-row-span: 11;
    }
    .cell.is-col-start-12-desktop {
      --bulma-grid-cell-column-start: 12;
    }
    .cell.is-col-end-12-desktop {
      --bulma-grid-cell-column-end: 12;
    }
    .cell.is-col-from-end-12-desktop {
      --bulma-grid-cell-column-start: -12;
    }
    .cell.is-col-span-12-desktop {
      --bulma-grid-cell-column-span: 12;
    }
    .cell.is-row-start-12-desktop {
      --bulma-grid-cell-row-start: 12;
    }
    .cell.is-row-end-12-desktop {
      --bulma-grid-cell-row-end: 12;
    }
    .cell.is-row-from-end-12-desktop {
      --bulma-grid-cell-row-start: -12;
    }
    .cell.is-row-span-12-desktop {
      --bulma-grid-cell-row-span: 12;
    }
  }
  @media screen and (min-width: 1024px) and (max-width: 1215px) {
    .cell.is-col-start-1-desktop-only {
      --bulma-grid-cell-column-start: 1;
    }
    .cell.is-col-end-1-desktop-only {
      --bulma-grid-cell-column-end: 1;
    }
    .cell.is-col-from-end-1-desktop-only {
      --bulma-grid-cell-column-start: -1;
    }
    .cell.is-col-span-1-desktop-only {
      --bulma-grid-cell-column-span: 1;
    }
    .cell.is-row-start-1-desktop-only {
      --bulma-grid-cell-row-start: 1;
    }
    .cell.is-row-end-1-desktop-only {
      --bulma-grid-cell-row-end: 1;
    }
    .cell.is-row-from-end-1-desktop-only {
      --bulma-grid-cell-row-start: -1;
    }
    .cell.is-row-span-1-desktop-only {
      --bulma-grid-cell-row-span: 1;
    }
    .cell.is-col-start-2-desktop-only {
      --bulma-grid-cell-column-start: 2;
    }
    .cell.is-col-end-2-desktop-only {
      --bulma-grid-cell-column-end: 2;
    }
    .cell.is-col-from-end-2-desktop-only {
      --bulma-grid-cell-column-start: -2;
    }
    .cell.is-col-span-2-desktop-only {
      --bulma-grid-cell-column-span: 2;
    }
    .cell.is-row-start-2-desktop-only {
      --bulma-grid-cell-row-start: 2;
    }
    .cell.is-row-end-2-desktop-only {
      --bulma-grid-cell-row-end: 2;
    }
    .cell.is-row-from-end-2-desktop-only {
      --bulma-grid-cell-row-start: -2;
    }
    .cell.is-row-span-2-desktop-only {
      --bulma-grid-cell-row-span: 2;
    }
    .cell.is-col-start-3-desktop-only {
      --bulma-grid-cell-column-start: 3;
    }
    .cell.is-col-end-3-desktop-only {
      --bulma-grid-cell-column-end: 3;
    }
    .cell.is-col-from-end-3-desktop-only {
      --bulma-grid-cell-column-start: -3;
    }
    .cell.is-col-span-3-desktop-only {
      --bulma-grid-cell-column-span: 3;
    }
    .cell.is-row-start-3-desktop-only {
      --bulma-grid-cell-row-start: 3;
    }
    .cell.is-row-end-3-desktop-only {
      --bulma-grid-cell-row-end: 3;
    }
    .cell.is-row-from-end-3-desktop-only {
      --bulma-grid-cell-row-start: -3;
    }
    .cell.is-row-span-3-desktop-only {
      --bulma-grid-cell-row-span: 3;
    }
    .cell.is-col-start-4-desktop-only {
      --bulma-grid-cell-column-start: 4;
    }
    .cell.is-col-end-4-desktop-only {
      --bulma-grid-cell-column-end: 4;
    }
    .cell.is-col-from-end-4-desktop-only {
      --bulma-grid-cell-column-start: -4;
    }
    .cell.is-col-span-4-desktop-only {
      --bulma-grid-cell-column-span: 4;
    }
    .cell.is-row-start-4-desktop-only {
      --bulma-grid-cell-row-start: 4;
    }
    .cell.is-row-end-4-desktop-only {
      --bulma-grid-cell-row-end: 4;
    }
    .cell.is-row-from-end-4-desktop-only {
      --bulma-grid-cell-row-start: -4;
    }
    .cell.is-row-span-4-desktop-only {
      --bulma-grid-cell-row-span: 4;
    }
    .cell.is-col-start-5-desktop-only {
      --bulma-grid-cell-column-start: 5;
    }
    .cell.is-col-end-5-desktop-only {
      --bulma-grid-cell-column-end: 5;
    }
    .cell.is-col-from-end-5-desktop-only {
      --bulma-grid-cell-column-start: -5;
    }
    .cell.is-col-span-5-desktop-only {
      --bulma-grid-cell-column-span: 5;
    }
    .cell.is-row-start-5-desktop-only {
      --bulma-grid-cell-row-start: 5;
    }
    .cell.is-row-end-5-desktop-only {
      --bulma-grid-cell-row-end: 5;
    }
    .cell.is-row-from-end-5-desktop-only {
      --bulma-grid-cell-row-start: -5;
    }
    .cell.is-row-span-5-desktop-only {
      --bulma-grid-cell-row-span: 5;
    }
    .cell.is-col-start-6-desktop-only {
      --bulma-grid-cell-column-start: 6;
    }
    .cell.is-col-end-6-desktop-only {
      --bulma-grid-cell-column-end: 6;
    }
    .cell.is-col-from-end-6-desktop-only {
      --bulma-grid-cell-column-start: -6;
    }
    .cell.is-col-span-6-desktop-only {
      --bulma-grid-cell-column-span: 6;
    }
    .cell.is-row-start-6-desktop-only {
      --bulma-grid-cell-row-start: 6;
    }
    .cell.is-row-end-6-desktop-only {
      --bulma-grid-cell-row-end: 6;
    }
    .cell.is-row-from-end-6-desktop-only {
      --bulma-grid-cell-row-start: -6;
    }
    .cell.is-row-span-6-desktop-only {
      --bulma-grid-cell-row-span: 6;
    }
    .cell.is-col-start-7-desktop-only {
      --bulma-grid-cell-column-start: 7;
    }
    .cell.is-col-end-7-desktop-only {
      --bulma-grid-cell-column-end: 7;
    }
    .cell.is-col-from-end-7-desktop-only {
      --bulma-grid-cell-column-start: -7;
    }
    .cell.is-col-span-7-desktop-only {
      --bulma-grid-cell-column-span: 7;
    }
    .cell.is-row-start-7-desktop-only {
      --bulma-grid-cell-row-start: 7;
    }
    .cell.is-row-end-7-desktop-only {
      --bulma-grid-cell-row-end: 7;
    }
    .cell.is-row-from-end-7-desktop-only {
      --bulma-grid-cell-row-start: -7;
    }
    .cell.is-row-span-7-desktop-only {
      --bulma-grid-cell-row-span: 7;
    }
    .cell.is-col-start-8-desktop-only {
      --bulma-grid-cell-column-start: 8;
    }
    .cell.is-col-end-8-desktop-only {
      --bulma-grid-cell-column-end: 8;
    }
    .cell.is-col-from-end-8-desktop-only {
      --bulma-grid-cell-column-start: -8;
    }
    .cell.is-col-span-8-desktop-only {
      --bulma-grid-cell-column-span: 8;
    }
    .cell.is-row-start-8-desktop-only {
      --bulma-grid-cell-row-start: 8;
    }
    .cell.is-row-end-8-desktop-only {
      --bulma-grid-cell-row-end: 8;
    }
    .cell.is-row-from-end-8-desktop-only {
      --bulma-grid-cell-row-start: -8;
    }
    .cell.is-row-span-8-desktop-only {
      --bulma-grid-cell-row-span: 8;
    }
    .cell.is-col-start-9-desktop-only {
      --bulma-grid-cell-column-start: 9;
    }
    .cell.is-col-end-9-desktop-only {
      --bulma-grid-cell-column-end: 9;
    }
    .cell.is-col-from-end-9-desktop-only {
      --bulma-grid-cell-column-start: -9;
    }
    .cell.is-col-span-9-desktop-only {
      --bulma-grid-cell-column-span: 9;
    }
    .cell.is-row-start-9-desktop-only {
      --bulma-grid-cell-row-start: 9;
    }
    .cell.is-row-end-9-desktop-only {
      --bulma-grid-cell-row-end: 9;
    }
    .cell.is-row-from-end-9-desktop-only {
      --bulma-grid-cell-row-start: -9;
    }
    .cell.is-row-span-9-desktop-only {
      --bulma-grid-cell-row-span: 9;
    }
    .cell.is-col-start-10-desktop-only {
      --bulma-grid-cell-column-start: 10;
    }
    .cell.is-col-end-10-desktop-only {
      --bulma-grid-cell-column-end: 10;
    }
    .cell.is-col-from-end-10-desktop-only {
      --bulma-grid-cell-column-start: -10;
    }
    .cell.is-col-span-10-desktop-only {
      --bulma-grid-cell-column-span: 10;
    }
    .cell.is-row-start-10-desktop-only {
      --bulma-grid-cell-row-start: 10;
    }
    .cell.is-row-end-10-desktop-only {
      --bulma-grid-cell-row-end: 10;
    }
    .cell.is-row-from-end-10-desktop-only {
      --bulma-grid-cell-row-start: -10;
    }
    .cell.is-row-span-10-desktop-only {
      --bulma-grid-cell-row-span: 10;
    }
    .cell.is-col-start-11-desktop-only {
      --bulma-grid-cell-column-start: 11;
    }
    .cell.is-col-end-11-desktop-only {
      --bulma-grid-cell-column-end: 11;
    }
    .cell.is-col-from-end-11-desktop-only {
      --bulma-grid-cell-column-start: -11;
    }
    .cell.is-col-span-11-desktop-only {
      --bulma-grid-cell-column-span: 11;
    }
    .cell.is-row-start-11-desktop-only {
      --bulma-grid-cell-row-start: 11;
    }
    .cell.is-row-end-11-desktop-only {
      --bulma-grid-cell-row-end: 11;
    }
    .cell.is-row-from-end-11-desktop-only {
      --bulma-grid-cell-row-start: -11;
    }
    .cell.is-row-span-11-desktop-only {
      --bulma-grid-cell-row-span: 11;
    }
    .cell.is-col-start-12-desktop-only {
      --bulma-grid-cell-column-start: 12;
    }
    .cell.is-col-end-12-desktop-only {
      --bulma-grid-cell-column-end: 12;
    }
    .cell.is-col-from-end-12-desktop-only {
      --bulma-grid-cell-column-start: -12;
    }
    .cell.is-col-span-12-desktop-only {
      --bulma-grid-cell-column-span: 12;
    }
    .cell.is-row-start-12-desktop-only {
      --bulma-grid-cell-row-start: 12;
    }
    .cell.is-row-end-12-desktop-only {
      --bulma-grid-cell-row-end: 12;
    }
    .cell.is-row-from-end-12-desktop-only {
      --bulma-grid-cell-row-start: -12;
    }
    .cell.is-row-span-12-desktop-only {
      --bulma-grid-cell-row-span: 12;
    }
  }
  @media screen and (min-width: 1216px) {
    .cell.is-col-start-1-widescreen {
      --bulma-grid-cell-column-start: 1;
    }
    .cell.is-col-end-1-widescreen {
      --bulma-grid-cell-column-end: 1;
    }
    .cell.is-col-from-end-1-widescreen {
      --bulma-grid-cell-column-start: -1;
    }
    .cell.is-col-span-1-widescreen {
      --bulma-grid-cell-column-span: 1;
    }
    .cell.is-row-start-1-widescreen {
      --bulma-grid-cell-row-start: 1;
    }
    .cell.is-row-end-1-widescreen {
      --bulma-grid-cell-row-end: 1;
    }
    .cell.is-row-from-end-1-widescreen {
      --bulma-grid-cell-row-start: -1;
    }
    .cell.is-row-span-1-widescreen {
      --bulma-grid-cell-row-span: 1;
    }
    .cell.is-col-start-2-widescreen {
      --bulma-grid-cell-column-start: 2;
    }
    .cell.is-col-end-2-widescreen {
      --bulma-grid-cell-column-end: 2;
    }
    .cell.is-col-from-end-2-widescreen {
      --bulma-grid-cell-column-start: -2;
    }
    .cell.is-col-span-2-widescreen {
      --bulma-grid-cell-column-span: 2;
    }
    .cell.is-row-start-2-widescreen {
      --bulma-grid-cell-row-start: 2;
    }
    .cell.is-row-end-2-widescreen {
      --bulma-grid-cell-row-end: 2;
    }
    .cell.is-row-from-end-2-widescreen {
      --bulma-grid-cell-row-start: -2;
    }
    .cell.is-row-span-2-widescreen {
      --bulma-grid-cell-row-span: 2;
    }
    .cell.is-col-start-3-widescreen {
      --bulma-grid-cell-column-start: 3;
    }
    .cell.is-col-end-3-widescreen {
      --bulma-grid-cell-column-end: 3;
    }
    .cell.is-col-from-end-3-widescreen {
      --bulma-grid-cell-column-start: -3;
    }
    .cell.is-col-span-3-widescreen {
      --bulma-grid-cell-column-span: 3;
    }
    .cell.is-row-start-3-widescreen {
      --bulma-grid-cell-row-start: 3;
    }
    .cell.is-row-end-3-widescreen {
      --bulma-grid-cell-row-end: 3;
    }
    .cell.is-row-from-end-3-widescreen {
      --bulma-grid-cell-row-start: -3;
    }
    .cell.is-row-span-3-widescreen {
      --bulma-grid-cell-row-span: 3;
    }
    .cell.is-col-start-4-widescreen {
      --bulma-grid-cell-column-start: 4;
    }
    .cell.is-col-end-4-widescreen {
      --bulma-grid-cell-column-end: 4;
    }
    .cell.is-col-from-end-4-widescreen {
      --bulma-grid-cell-column-start: -4;
    }
    .cell.is-col-span-4-widescreen {
      --bulma-grid-cell-column-span: 4;
    }
    .cell.is-row-start-4-widescreen {
      --bulma-grid-cell-row-start: 4;
    }
    .cell.is-row-end-4-widescreen {
      --bulma-grid-cell-row-end: 4;
    }
    .cell.is-row-from-end-4-widescreen {
      --bulma-grid-cell-row-start: -4;
    }
    .cell.is-row-span-4-widescreen {
      --bulma-grid-cell-row-span: 4;
    }
    .cell.is-col-start-5-widescreen {
      --bulma-grid-cell-column-start: 5;
    }
    .cell.is-col-end-5-widescreen {
      --bulma-grid-cell-column-end: 5;
    }
    .cell.is-col-from-end-5-widescreen {
      --bulma-grid-cell-column-start: -5;
    }
    .cell.is-col-span-5-widescreen {
      --bulma-grid-cell-column-span: 5;
    }
    .cell.is-row-start-5-widescreen {
      --bulma-grid-cell-row-start: 5;
    }
    .cell.is-row-end-5-widescreen {
      --bulma-grid-cell-row-end: 5;
    }
    .cell.is-row-from-end-5-widescreen {
      --bulma-grid-cell-row-start: -5;
    }
    .cell.is-row-span-5-widescreen {
      --bulma-grid-cell-row-span: 5;
    }
    .cell.is-col-start-6-widescreen {
      --bulma-grid-cell-column-start: 6;
    }
    .cell.is-col-end-6-widescreen {
      --bulma-grid-cell-column-end: 6;
    }
    .cell.is-col-from-end-6-widescreen {
      --bulma-grid-cell-column-start: -6;
    }
    .cell.is-col-span-6-widescreen {
      --bulma-grid-cell-column-span: 6;
    }
    .cell.is-row-start-6-widescreen {
      --bulma-grid-cell-row-start: 6;
    }
    .cell.is-row-end-6-widescreen {
      --bulma-grid-cell-row-end: 6;
    }
    .cell.is-row-from-end-6-widescreen {
      --bulma-grid-cell-row-start: -6;
    }
    .cell.is-row-span-6-widescreen {
      --bulma-grid-cell-row-span: 6;
    }
    .cell.is-col-start-7-widescreen {
      --bulma-grid-cell-column-start: 7;
    }
    .cell.is-col-end-7-widescreen {
      --bulma-grid-cell-column-end: 7;
    }
    .cell.is-col-from-end-7-widescreen {
      --bulma-grid-cell-column-start: -7;
    }
    .cell.is-col-span-7-widescreen {
      --bulma-grid-cell-column-span: 7;
    }
    .cell.is-row-start-7-widescreen {
      --bulma-grid-cell-row-start: 7;
    }
    .cell.is-row-end-7-widescreen {
      --bulma-grid-cell-row-end: 7;
    }
    .cell.is-row-from-end-7-widescreen {
      --bulma-grid-cell-row-start: -7;
    }
    .cell.is-row-span-7-widescreen {
      --bulma-grid-cell-row-span: 7;
    }
    .cell.is-col-start-8-widescreen {
      --bulma-grid-cell-column-start: 8;
    }
    .cell.is-col-end-8-widescreen {
      --bulma-grid-cell-column-end: 8;
    }
    .cell.is-col-from-end-8-widescreen {
      --bulma-grid-cell-column-start: -8;
    }
    .cell.is-col-span-8-widescreen {
      --bulma-grid-cell-column-span: 8;
    }
    .cell.is-row-start-8-widescreen {
      --bulma-grid-cell-row-start: 8;
    }
    .cell.is-row-end-8-widescreen {
      --bulma-grid-cell-row-end: 8;
    }
    .cell.is-row-from-end-8-widescreen {
      --bulma-grid-cell-row-start: -8;
    }
    .cell.is-row-span-8-widescreen {
      --bulma-grid-cell-row-span: 8;
    }
    .cell.is-col-start-9-widescreen {
      --bulma-grid-cell-column-start: 9;
    }
    .cell.is-col-end-9-widescreen {
      --bulma-grid-cell-column-end: 9;
    }
    .cell.is-col-from-end-9-widescreen {
      --bulma-grid-cell-column-start: -9;
    }
    .cell.is-col-span-9-widescreen {
      --bulma-grid-cell-column-span: 9;
    }
    .cell.is-row-start-9-widescreen {
      --bulma-grid-cell-row-start: 9;
    }
    .cell.is-row-end-9-widescreen {
      --bulma-grid-cell-row-end: 9;
    }
    .cell.is-row-from-end-9-widescreen {
      --bulma-grid-cell-row-start: -9;
    }
    .cell.is-row-span-9-widescreen {
      --bulma-grid-cell-row-span: 9;
    }
    .cell.is-col-start-10-widescreen {
      --bulma-grid-cell-column-start: 10;
    }
    .cell.is-col-end-10-widescreen {
      --bulma-grid-cell-column-end: 10;
    }
    .cell.is-col-from-end-10-widescreen {
      --bulma-grid-cell-column-start: -10;
    }
    .cell.is-col-span-10-widescreen {
      --bulma-grid-cell-column-span: 10;
    }
    .cell.is-row-start-10-widescreen {
      --bulma-grid-cell-row-start: 10;
    }
    .cell.is-row-end-10-widescreen {
      --bulma-grid-cell-row-end: 10;
    }
    .cell.is-row-from-end-10-widescreen {
      --bulma-grid-cell-row-start: -10;
    }
    .cell.is-row-span-10-widescreen {
      --bulma-grid-cell-row-span: 10;
    }
    .cell.is-col-start-11-widescreen {
      --bulma-grid-cell-column-start: 11;
    }
    .cell.is-col-end-11-widescreen {
      --bulma-grid-cell-column-end: 11;
    }
    .cell.is-col-from-end-11-widescreen {
      --bulma-grid-cell-column-start: -11;
    }
    .cell.is-col-span-11-widescreen {
      --bulma-grid-cell-column-span: 11;
    }
    .cell.is-row-start-11-widescreen {
      --bulma-grid-cell-row-start: 11;
    }
    .cell.is-row-end-11-widescreen {
      --bulma-grid-cell-row-end: 11;
    }
    .cell.is-row-from-end-11-widescreen {
      --bulma-grid-cell-row-start: -11;
    }
    .cell.is-row-span-11-widescreen {
      --bulma-grid-cell-row-span: 11;
    }
    .cell.is-col-start-12-widescreen {
      --bulma-grid-cell-column-start: 12;
    }
    .cell.is-col-end-12-widescreen {
      --bulma-grid-cell-column-end: 12;
    }
    .cell.is-col-from-end-12-widescreen {
      --bulma-grid-cell-column-start: -12;
    }
    .cell.is-col-span-12-widescreen {
      --bulma-grid-cell-column-span: 12;
    }
    .cell.is-row-start-12-widescreen {
      --bulma-grid-cell-row-start: 12;
    }
    .cell.is-row-end-12-widescreen {
      --bulma-grid-cell-row-end: 12;
    }
    .cell.is-row-from-end-12-widescreen {
      --bulma-grid-cell-row-start: -12;
    }
    .cell.is-row-span-12-widescreen {
      --bulma-grid-cell-row-span: 12;
    }
  }
  @media screen and (min-width: 1216px) and (max-width: 1407px) {
    .cell.is-col-start-1-widescreen-only {
      --bulma-grid-cell-column-start: 1;
    }
    .cell.is-col-end-1-widescreen-only {
      --bulma-grid-cell-column-end: 1;
    }
    .cell.is-col-from-end-1-widescreen-only {
      --bulma-grid-cell-column-start: -1;
    }
    .cell.is-col-span-1-widescreen-only {
      --bulma-grid-cell-column-span: 1;
    }
    .cell.is-row-start-1-widescreen-only {
      --bulma-grid-cell-row-start: 1;
    }
    .cell.is-row-end-1-widescreen-only {
      --bulma-grid-cell-row-end: 1;
    }
    .cell.is-row-from-end-1-widescreen-only {
      --bulma-grid-cell-row-start: -1;
    }
    .cell.is-row-span-1-widescreen-only {
      --bulma-grid-cell-row-span: 1;
    }
    .cell.is-col-start-2-widescreen-only {
      --bulma-grid-cell-column-start: 2;
    }
    .cell.is-col-end-2-widescreen-only {
      --bulma-grid-cell-column-end: 2;
    }
    .cell.is-col-from-end-2-widescreen-only {
      --bulma-grid-cell-column-start: -2;
    }
    .cell.is-col-span-2-widescreen-only {
      --bulma-grid-cell-column-span: 2;
    }
    .cell.is-row-start-2-widescreen-only {
      --bulma-grid-cell-row-start: 2;
    }
    .cell.is-row-end-2-widescreen-only {
      --bulma-grid-cell-row-end: 2;
    }
    .cell.is-row-from-end-2-widescreen-only {
      --bulma-grid-cell-row-start: -2;
    }
    .cell.is-row-span-2-widescreen-only {
      --bulma-grid-cell-row-span: 2;
    }
    .cell.is-col-start-3-widescreen-only {
      --bulma-grid-cell-column-start: 3;
    }
    .cell.is-col-end-3-widescreen-only {
      --bulma-grid-cell-column-end: 3;
    }
    .cell.is-col-from-end-3-widescreen-only {
      --bulma-grid-cell-column-start: -3;
    }
    .cell.is-col-span-3-widescreen-only {
      --bulma-grid-cell-column-span: 3;
    }
    .cell.is-row-start-3-widescreen-only {
      --bulma-grid-cell-row-start: 3;
    }
    .cell.is-row-end-3-widescreen-only {
      --bulma-grid-cell-row-end: 3;
    }
    .cell.is-row-from-end-3-widescreen-only {
      --bulma-grid-cell-row-start: -3;
    }
    .cell.is-row-span-3-widescreen-only {
      --bulma-grid-cell-row-span: 3;
    }
    .cell.is-col-start-4-widescreen-only {
      --bulma-grid-cell-column-start: 4;
    }
    .cell.is-col-end-4-widescreen-only {
      --bulma-grid-cell-column-end: 4;
    }
    .cell.is-col-from-end-4-widescreen-only {
      --bulma-grid-cell-column-start: -4;
    }
    .cell.is-col-span-4-widescreen-only {
      --bulma-grid-cell-column-span: 4;
    }
    .cell.is-row-start-4-widescreen-only {
      --bulma-grid-cell-row-start: 4;
    }
    .cell.is-row-end-4-widescreen-only {
      --bulma-grid-cell-row-end: 4;
    }
    .cell.is-row-from-end-4-widescreen-only {
      --bulma-grid-cell-row-start: -4;
    }
    .cell.is-row-span-4-widescreen-only {
      --bulma-grid-cell-row-span: 4;
    }
    .cell.is-col-start-5-widescreen-only {
      --bulma-grid-cell-column-start: 5;
    }
    .cell.is-col-end-5-widescreen-only {
      --bulma-grid-cell-column-end: 5;
    }
    .cell.is-col-from-end-5-widescreen-only {
      --bulma-grid-cell-column-start: -5;
    }
    .cell.is-col-span-5-widescreen-only {
      --bulma-grid-cell-column-span: 5;
    }
    .cell.is-row-start-5-widescreen-only {
      --bulma-grid-cell-row-start: 5;
    }
    .cell.is-row-end-5-widescreen-only {
      --bulma-grid-cell-row-end: 5;
    }
    .cell.is-row-from-end-5-widescreen-only {
      --bulma-grid-cell-row-start: -5;
    }
    .cell.is-row-span-5-widescreen-only {
      --bulma-grid-cell-row-span: 5;
    }
    .cell.is-col-start-6-widescreen-only {
      --bulma-grid-cell-column-start: 6;
    }
    .cell.is-col-end-6-widescreen-only {
      --bulma-grid-cell-column-end: 6;
    }
    .cell.is-col-from-end-6-widescreen-only {
      --bulma-grid-cell-column-start: -6;
    }
    .cell.is-col-span-6-widescreen-only {
      --bulma-grid-cell-column-span: 6;
    }
    .cell.is-row-start-6-widescreen-only {
      --bulma-grid-cell-row-start: 6;
    }
    .cell.is-row-end-6-widescreen-only {
      --bulma-grid-cell-row-end: 6;
    }
    .cell.is-row-from-end-6-widescreen-only {
      --bulma-grid-cell-row-start: -6;
    }
    .cell.is-row-span-6-widescreen-only {
      --bulma-grid-cell-row-span: 6;
    }
    .cell.is-col-start-7-widescreen-only {
      --bulma-grid-cell-column-start: 7;
    }
    .cell.is-col-end-7-widescreen-only {
      --bulma-grid-cell-column-end: 7;
    }
    .cell.is-col-from-end-7-widescreen-only {
      --bulma-grid-cell-column-start: -7;
    }
    .cell.is-col-span-7-widescreen-only {
      --bulma-grid-cell-column-span: 7;
    }
    .cell.is-row-start-7-widescreen-only {
      --bulma-grid-cell-row-start: 7;
    }
    .cell.is-row-end-7-widescreen-only {
      --bulma-grid-cell-row-end: 7;
    }
    .cell.is-row-from-end-7-widescreen-only {
      --bulma-grid-cell-row-start: -7;
    }
    .cell.is-row-span-7-widescreen-only {
      --bulma-grid-cell-row-span: 7;
    }
    .cell.is-col-start-8-widescreen-only {
      --bulma-grid-cell-column-start: 8;
    }
    .cell.is-col-end-8-widescreen-only {
      --bulma-grid-cell-column-end: 8;
    }
    .cell.is-col-from-end-8-widescreen-only {
      --bulma-grid-cell-column-start: -8;
    }
    .cell.is-col-span-8-widescreen-only {
      --bulma-grid-cell-column-span: 8;
    }
    .cell.is-row-start-8-widescreen-only {
      --bulma-grid-cell-row-start: 8;
    }
    .cell.is-row-end-8-widescreen-only {
      --bulma-grid-cell-row-end: 8;
    }
    .cell.is-row-from-end-8-widescreen-only {
      --bulma-grid-cell-row-start: -8;
    }
    .cell.is-row-span-8-widescreen-only {
      --bulma-grid-cell-row-span: 8;
    }
    .cell.is-col-start-9-widescreen-only {
      --bulma-grid-cell-column-start: 9;
    }
    .cell.is-col-end-9-widescreen-only {
      --bulma-grid-cell-column-end: 9;
    }
    .cell.is-col-from-end-9-widescreen-only {
      --bulma-grid-cell-column-start: -9;
    }
    .cell.is-col-span-9-widescreen-only {
      --bulma-grid-cell-column-span: 9;
    }
    .cell.is-row-start-9-widescreen-only {
      --bulma-grid-cell-row-start: 9;
    }
    .cell.is-row-end-9-widescreen-only {
      --bulma-grid-cell-row-end: 9;
    }
    .cell.is-row-from-end-9-widescreen-only {
      --bulma-grid-cell-row-start: -9;
    }
    .cell.is-row-span-9-widescreen-only {
      --bulma-grid-cell-row-span: 9;
    }
    .cell.is-col-start-10-widescreen-only {
      --bulma-grid-cell-column-start: 10;
    }
    .cell.is-col-end-10-widescreen-only {
      --bulma-grid-cell-column-end: 10;
    }
    .cell.is-col-from-end-10-widescreen-only {
      --bulma-grid-cell-column-start: -10;
    }
    .cell.is-col-span-10-widescreen-only {
      --bulma-grid-cell-column-span: 10;
    }
    .cell.is-row-start-10-widescreen-only {
      --bulma-grid-cell-row-start: 10;
    }
    .cell.is-row-end-10-widescreen-only {
      --bulma-grid-cell-row-end: 10;
    }
    .cell.is-row-from-end-10-widescreen-only {
      --bulma-grid-cell-row-start: -10;
    }
    .cell.is-row-span-10-widescreen-only {
      --bulma-grid-cell-row-span: 10;
    }
    .cell.is-col-start-11-widescreen-only {
      --bulma-grid-cell-column-start: 11;
    }
    .cell.is-col-end-11-widescreen-only {
      --bulma-grid-cell-column-end: 11;
    }
    .cell.is-col-from-end-11-widescreen-only {
      --bulma-grid-cell-column-start: -11;
    }
    .cell.is-col-span-11-widescreen-only {
      --bulma-grid-cell-column-span: 11;
    }
    .cell.is-row-start-11-widescreen-only {
      --bulma-grid-cell-row-start: 11;
    }
    .cell.is-row-end-11-widescreen-only {
      --bulma-grid-cell-row-end: 11;
    }
    .cell.is-row-from-end-11-widescreen-only {
      --bulma-grid-cell-row-start: -11;
    }
    .cell.is-row-span-11-widescreen-only {
      --bulma-grid-cell-row-span: 11;
    }
    .cell.is-col-start-12-widescreen-only {
      --bulma-grid-cell-column-start: 12;
    }
    .cell.is-col-end-12-widescreen-only {
      --bulma-grid-cell-column-end: 12;
    }
    .cell.is-col-from-end-12-widescreen-only {
      --bulma-grid-cell-column-start: -12;
    }
    .cell.is-col-span-12-widescreen-only {
      --bulma-grid-cell-column-span: 12;
    }
    .cell.is-row-start-12-widescreen-only {
      --bulma-grid-cell-row-start: 12;
    }
    .cell.is-row-end-12-widescreen-only {
      --bulma-grid-cell-row-end: 12;
    }
    .cell.is-row-from-end-12-widescreen-only {
      --bulma-grid-cell-row-start: -12;
    }
    .cell.is-row-span-12-widescreen-only {
      --bulma-grid-cell-row-span: 12;
    }
  }
  @media screen and (min-width: 1408px) {
    .cell.is-col-start-1-fullhd {
      --bulma-grid-cell-column-start: 1;
    }
    .cell.is-col-end-1-fullhd {
      --bulma-grid-cell-column-end: 1;
    }
    .cell.is-col-from-end-1-fullhd {
      --bulma-grid-cell-column-start: -1;
    }
    .cell.is-col-span-1-fullhd {
      --bulma-grid-cell-column-span: 1;
    }
    .cell.is-row-start-1-fullhd {
      --bulma-grid-cell-row-start: 1;
    }
    .cell.is-row-end-1-fullhd {
      --bulma-grid-cell-row-end: 1;
    }
    .cell.is-row-from-end-1-fullhd {
      --bulma-grid-cell-row-start: -1;
    }
    .cell.is-row-span-1-fullhd {
      --bulma-grid-cell-row-span: 1;
    }
    .cell.is-col-start-2-fullhd {
      --bulma-grid-cell-column-start: 2;
    }
    .cell.is-col-end-2-fullhd {
      --bulma-grid-cell-column-end: 2;
    }
    .cell.is-col-from-end-2-fullhd {
      --bulma-grid-cell-column-start: -2;
    }
    .cell.is-col-span-2-fullhd {
      --bulma-grid-cell-column-span: 2;
    }
    .cell.is-row-start-2-fullhd {
      --bulma-grid-cell-row-start: 2;
    }
    .cell.is-row-end-2-fullhd {
      --bulma-grid-cell-row-end: 2;
    }
    .cell.is-row-from-end-2-fullhd {
      --bulma-grid-cell-row-start: -2;
    }
    .cell.is-row-span-2-fullhd {
      --bulma-grid-cell-row-span: 2;
    }
    .cell.is-col-start-3-fullhd {
      --bulma-grid-cell-column-start: 3;
    }
    .cell.is-col-end-3-fullhd {
      --bulma-grid-cell-column-end: 3;
    }
    .cell.is-col-from-end-3-fullhd {
      --bulma-grid-cell-column-start: -3;
    }
    .cell.is-col-span-3-fullhd {
      --bulma-grid-cell-column-span: 3;
    }
    .cell.is-row-start-3-fullhd {
      --bulma-grid-cell-row-start: 3;
    }
    .cell.is-row-end-3-fullhd {
      --bulma-grid-cell-row-end: 3;
    }
    .cell.is-row-from-end-3-fullhd {
      --bulma-grid-cell-row-start: -3;
    }
    .cell.is-row-span-3-fullhd {
      --bulma-grid-cell-row-span: 3;
    }
    .cell.is-col-start-4-fullhd {
      --bulma-grid-cell-column-start: 4;
    }
    .cell.is-col-end-4-fullhd {
      --bulma-grid-cell-column-end: 4;
    }
    .cell.is-col-from-end-4-fullhd {
      --bulma-grid-cell-column-start: -4;
    }
    .cell.is-col-span-4-fullhd {
      --bulma-grid-cell-column-span: 4;
    }
    .cell.is-row-start-4-fullhd {
      --bulma-grid-cell-row-start: 4;
    }
    .cell.is-row-end-4-fullhd {
      --bulma-grid-cell-row-end: 4;
    }
    .cell.is-row-from-end-4-fullhd {
      --bulma-grid-cell-row-start: -4;
    }
    .cell.is-row-span-4-fullhd {
      --bulma-grid-cell-row-span: 4;
    }
    .cell.is-col-start-5-fullhd {
      --bulma-grid-cell-column-start: 5;
    }
    .cell.is-col-end-5-fullhd {
      --bulma-grid-cell-column-end: 5;
    }
    .cell.is-col-from-end-5-fullhd {
      --bulma-grid-cell-column-start: -5;
    }
    .cell.is-col-span-5-fullhd {
      --bulma-grid-cell-column-span: 5;
    }
    .cell.is-row-start-5-fullhd {
      --bulma-grid-cell-row-start: 5;
    }
    .cell.is-row-end-5-fullhd {
      --bulma-grid-cell-row-end: 5;
    }
    .cell.is-row-from-end-5-fullhd {
      --bulma-grid-cell-row-start: -5;
    }
    .cell.is-row-span-5-fullhd {
      --bulma-grid-cell-row-span: 5;
    }
    .cell.is-col-start-6-fullhd {
      --bulma-grid-cell-column-start: 6;
    }
    .cell.is-col-end-6-fullhd {
      --bulma-grid-cell-column-end: 6;
    }
    .cell.is-col-from-end-6-fullhd {
      --bulma-grid-cell-column-start: -6;
    }
    .cell.is-col-span-6-fullhd {
      --bulma-grid-cell-column-span: 6;
    }
    .cell.is-row-start-6-fullhd {
      --bulma-grid-cell-row-start: 6;
    }
    .cell.is-row-end-6-fullhd {
      --bulma-grid-cell-row-end: 6;
    }
    .cell.is-row-from-end-6-fullhd {
      --bulma-grid-cell-row-start: -6;
    }
    .cell.is-row-span-6-fullhd {
      --bulma-grid-cell-row-span: 6;
    }
    .cell.is-col-start-7-fullhd {
      --bulma-grid-cell-column-start: 7;
    }
    .cell.is-col-end-7-fullhd {
      --bulma-grid-cell-column-end: 7;
    }
    .cell.is-col-from-end-7-fullhd {
      --bulma-grid-cell-column-start: -7;
    }
    .cell.is-col-span-7-fullhd {
      --bulma-grid-cell-column-span: 7;
    }
    .cell.is-row-start-7-fullhd {
      --bulma-grid-cell-row-start: 7;
    }
    .cell.is-row-end-7-fullhd {
      --bulma-grid-cell-row-end: 7;
    }
    .cell.is-row-from-end-7-fullhd {
      --bulma-grid-cell-row-start: -7;
    }
    .cell.is-row-span-7-fullhd {
      --bulma-grid-cell-row-span: 7;
    }
    .cell.is-col-start-8-fullhd {
      --bulma-grid-cell-column-start: 8;
    }
    .cell.is-col-end-8-fullhd {
      --bulma-grid-cell-column-end: 8;
    }
    .cell.is-col-from-end-8-fullhd {
      --bulma-grid-cell-column-start: -8;
    }
    .cell.is-col-span-8-fullhd {
      --bulma-grid-cell-column-span: 8;
    }
    .cell.is-row-start-8-fullhd {
      --bulma-grid-cell-row-start: 8;
    }
    .cell.is-row-end-8-fullhd {
      --bulma-grid-cell-row-end: 8;
    }
    .cell.is-row-from-end-8-fullhd {
      --bulma-grid-cell-row-start: -8;
    }
    .cell.is-row-span-8-fullhd {
      --bulma-grid-cell-row-span: 8;
    }
    .cell.is-col-start-9-fullhd {
      --bulma-grid-cell-column-start: 9;
    }
    .cell.is-col-end-9-fullhd {
      --bulma-grid-cell-column-end: 9;
    }
    .cell.is-col-from-end-9-fullhd {
      --bulma-grid-cell-column-start: -9;
    }
    .cell.is-col-span-9-fullhd {
      --bulma-grid-cell-column-span: 9;
    }
    .cell.is-row-start-9-fullhd {
      --bulma-grid-cell-row-start: 9;
    }
    .cell.is-row-end-9-fullhd {
      --bulma-grid-cell-row-end: 9;
    }
    .cell.is-row-from-end-9-fullhd {
      --bulma-grid-cell-row-start: -9;
    }
    .cell.is-row-span-9-fullhd {
      --bulma-grid-cell-row-span: 9;
    }
    .cell.is-col-start-10-fullhd {
      --bulma-grid-cell-column-start: 10;
    }
    .cell.is-col-end-10-fullhd {
      --bulma-grid-cell-column-end: 10;
    }
    .cell.is-col-from-end-10-fullhd {
      --bulma-grid-cell-column-start: -10;
    }
    .cell.is-col-span-10-fullhd {
      --bulma-grid-cell-column-span: 10;
    }
    .cell.is-row-start-10-fullhd {
      --bulma-grid-cell-row-start: 10;
    }
    .cell.is-row-end-10-fullhd {
      --bulma-grid-cell-row-end: 10;
    }
    .cell.is-row-from-end-10-fullhd {
      --bulma-grid-cell-row-start: -10;
    }
    .cell.is-row-span-10-fullhd {
      --bulma-grid-cell-row-span: 10;
    }
    .cell.is-col-start-11-fullhd {
      --bulma-grid-cell-column-start: 11;
    }
    .cell.is-col-end-11-fullhd {
      --bulma-grid-cell-column-end: 11;
    }
    .cell.is-col-from-end-11-fullhd {
      --bulma-grid-cell-column-start: -11;
    }
    .cell.is-col-span-11-fullhd {
      --bulma-grid-cell-column-span: 11;
    }
    .cell.is-row-start-11-fullhd {
      --bulma-grid-cell-row-start: 11;
    }
    .cell.is-row-end-11-fullhd {
      --bulma-grid-cell-row-end: 11;
    }
    .cell.is-row-from-end-11-fullhd {
      --bulma-grid-cell-row-start: -11;
    }
    .cell.is-row-span-11-fullhd {
      --bulma-grid-cell-row-span: 11;
    }
    .cell.is-col-start-12-fullhd {
      --bulma-grid-cell-column-start: 12;
    }
    .cell.is-col-end-12-fullhd {
      --bulma-grid-cell-column-end: 12;
    }
    .cell.is-col-from-end-12-fullhd {
      --bulma-grid-cell-column-start: -12;
    }
    .cell.is-col-span-12-fullhd {
      --bulma-grid-cell-column-span: 12;
    }
    .cell.is-row-start-12-fullhd {
      --bulma-grid-cell-row-start: 12;
    }
    .cell.is-row-end-12-fullhd {
      --bulma-grid-cell-row-end: 12;
    }
    .cell.is-row-from-end-12-fullhd {
      --bulma-grid-cell-row-start: -12;
    }
    .cell.is-row-span-12-fullhd {
      --bulma-grid-cell-row-span: 12;
    }
  }