/**
 * Site header
 */
.site-header {
  color: var(--color-primary);
  background-color: var(--color-secondary);
  text-transform: uppercase;
}

.site-nav {
  background-color: var(--color-secondary);
}

#nav-top {
  min-height: 2em;
  text-align: right;
}

#breadcrumbs {
  margin: 0;
}

.logo-fdn {
  width: 150px;
}

/* home */

.logo {
	background: url('/assets/img/logo-fdn.svg');
	width: 81px;
	height: 30px;
	/*padding: 5px;*/
	margin: 10px;
	display: flex;
	background-size: 81px 30px;
}

#banniere-home {
	background: url('/assets/img/bandeau-texte.jpg') no-repeat center #8da9d1;
	height: 194px;
	width: 100%;
}

#banniere-home h1 {
	text-align: center;
	color: #fff;
	font-size: 18px;
	width: 30%;
	margin: auto;
	padding-top: 5px;
}

#banniere-home h1 span {
	font-weight: bold;
	font-size: 37px;
	text-transform: uppercase;}

/* rendre accessible le menu en cachant "accueil" mais en le laissant lisible aux lecteurs d'écran (pas de lien vide)*/
.menu-hidden {
  line-height: 0; 
  font-size: 0;
  color: transparent; 
}

.center {
  text-align: center;
}

/**
 * Site footer
 */
.site-footer {
  color: var(--color-primary);
  background-color: var(--color-secondary);
  text-align: center;
  padding-bottom: 1em;
  text-transform: uppercase;
}

#back {
text-align: right;
padding-right: 2em;
}



/**
 * Page content
 */
.page-content {
   padding-bottom: 2em;
 }

.post-meta {
  font-size: var(--font-small);
  color: var(--color-fdn5);
}

.post-link {
  display: block;
}

.pagination {
  padding: 2em 0;
}

.previous {
  padding-right: 2em;
}
.next {
  padding-left: 2em;
}

/**
 * Posts
 */
.post-header {
}

.post-title {
}

.post-content {
}

.bgblue {
  background-color: var(--color-accent);
  margin: 1em 0;
  padding: 1em;
}
.bleu {
color:var(--color-fdn5);
}

/** lunrsearch **/
#lunrsearchresults {padding-top: 0.35rem;}
.lunrsearchresult {padding-bottom: 1rem;}
.lunrsearchresult .title {color: var(--color-link);margin: 0;}
.lunrsearchresult .url {color: var(--color-fdn5)}
.lunrsearchresult a {display: block; color: var(--color-primary)}
.lunrsearchresult a:hover, .lunrsearchresult a:focus {text-decoration: none;}
.lunrsearchresult a:hover .title {text-decoration: underline;}