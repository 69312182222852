/* Reset some basic elements */
body /*, h1, h2, h3, h4, h5, h6, p, blockquote, pre, hr, dl, dd, figure*/ {
  margin: 0;
  padding: 0;
}

/* Clearfix */
%clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.content {
  flex: 1 0 auto;
}
.footer {
  flex-shrink: 0;
}

/*  */
html {
  scroll-behavior: smooth;
}

@media (prefers-reduced-motion: reduce) {
  html {
      scroll-behavior: auto;
  }
}

html,
body {
  height: 100%;
}

/* Layout */
article aside {
  background: var(--color-fdn3);
  border-left: 4px solid var(--color-fdn1);
  padding: 0.01rem 0.5rem;
}

body {
  max-width: 100%;
  color: var(--color-primary);
  line-height: var(--line-height);
  background-color: var(--color-secondary);
  font-family: var(--font-family);
  font-size: var(--font-normal);
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  overflow-x: hidden;
}

footer,
header,
main {
  padding: 0;
  margin:0;
  width: 100%;
}

.site-header, .site-footer {
  margin-bottom: 0;
  border-radius : 0 !important;
}

video { background-color:black; }

.container {
  margin: 0 auto;
  max-width: var(--width-content);
  padding: 0 1rem;
  @extend %clearfix;
}


hr {
  background-color: var(--color-fdn2);
  border: none;
  height: 1px;
  margin: 1rem 0;
  width: 100%;
}

section {
  display: flex;
  flex-wrap: wrap;
}
section img,
article img {
  max-width: 100%;
}

section pre {
  overflow: auto;
}

section aside {
  padding: 1rem;
  width: var(--width-card);
}

[hidden] {
  display: none;
}

/* Headers */
article header,
div header,
main header {
  padding-top: 1em;
}

header a b,
header a em,
header a i,
header a strong {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

header nav img {
  margin: 1rem 0;
}

section header {
  padding-top: 0;
  width: 100%;
}

/* Typography */
code,
samp {
  background-color: var(--color-bg4);
  border-radius: var(--border-radius);
  color: var(--color-fdn4);
  display: inline-block;
  margin: 0 0.1rem;
  padding: 0 0.5rem;
}

details {
  margin: 1.3rem 0;
}

details summary {
  font-weight: bold;
  cursor: pointer;
}

.h1,
h1 {
  font-weight: bold;
  line-height: 1;
  font-size: var(--font-large);
}

.h2,
h2,
.h3,
h3,
.h4,
.h5,
.h6,
h4,
h5,
h6 {
  font-weight:bold;
  line-height: 1.2;
}

.h2,
h2 {
  font-weight: bold;
  font-size: 1.5em;
}

.home .h3,
.home h3 {
  font-weight: bold;
  color: var(--color-fdn5);
}



mark {
  padding: 0.1rem;
}

ul, ol {
  margin-left: 0;
}

li {
  > ul,
  > ol {
    margin-bottom: 0;
  }
}

ol li,
ul li {
  padding: 0;
}

p {
  margin: 0 0 0.75rem;
  padding: 0;
  width: 100%;
}

pre {
  margin: 1rem 0;
  max-width: var(--width-card-wide);
  padding: 1rem 0;
}

pre code,
pre samp {
  display: block;
  max-width: var(--width-card-wide);
  padding: 0.5rem 2rem;
  white-space: pre-wrap;
}

sup {
  background-color: var(--color-secondary);
  border-radius: var(--border-radius);
  color: var(--color-secondary);
  font-size: var(--font-small);
  font-weight: bold;
  margin: 0.2rem;
  padding: 0.2rem 0.3rem;
  position: relative;
  top: -2px;
}

/* Links */

a {
  color: var(--color-link);
  display: inline-block;
  text-decoration: none;

  &:visited {
    color: var(--color-link);
  }

  &:hover, :active, :focus {
    color: var(--color-over);
    text-decoration: underline;
  }

  .social-media-list &:hover {
    text-decoration: none;

    .username {
      text-decoration: underline;
    }
  }
}

button, .button,
input[type="submit"] {
  border-radius: var(--border-radius);
  display: inline-block;
  font-weight: bold;
  line-height: var(--line-height);
  margin: 0.5rem 0;
  padding: 0.5rem 1rem;
  outline: none;
  transition: 0.3s;
  font-size: var(--font-normal);
}

button,
input[type="submit"] {
  font-family: var(--font-family);
}

button:hover, .button:hover, .button:focus,
input[type="submit"]:hover {
  cursor: pointer;
  background-color: var(--color-fdn2);
  color: var(--color-primary);
}

button:active,
input[type="submit"]:active {
  background-color: var(--color-fdn2);
  color: var(--color-primary);
}

button,
input[type="submit"] {
  background-color: var(--color-link);
  border: 2px solid var(--color-link);
  color: var(--color-secondary);
}

article aside a {
  color: var(--color-secondary);
}

/* Images */
img {
  border: 0;
  height: auto !important;
  max-width: 100%;
  vertical-align: middle;
}

figure {
  margin: 0;
  padding: 0;
}

figure > img {
  display: block;
}

figure figcaption {
  color: var(--color-fdn4);
}

p img {
  display: block;
  margin: 0 auto;
}

.legende {
  text-align: center;
  padding-bottom: 1em;
  font-size: var(--font-small);
}

/* Forms */
button:disabled,
input:disabled {
  background: var(--color-bg2);
  border-color: var(--color-bg2);
  color: var(--color-secondary);
  cursor: not-allowed;
}

button[disabled]:hover,
input[type="submit"][disabled]:hover {
  filter: none;
}

form {
  border: 1px solid var(--color-bg2);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow) var(--color-shadow);
  display: block;
  max-width: var(--width-card-wide);
  min-width: var(--width-card);
  padding: 1.5rem;
  text-align: var(--justify-normal);
}

form header {
  margin: 1.5rem 0;
  padding: 1.5rem 0;
}

input,
label,
select,
textarea {
  display: block;
  font-size: inherit;
  max-width: var(--width-card-wide);
}

input[type="checkbox"],
input[type="radio"] {
  display: inline-block;
}

input[type="checkbox"]+label,
input[type="radio"]+label {
  display: inline-block;
  font-weight: normal;
  position: relative;
  top: 1px;
}

input[type="range"] {
  padding: 0.4rem 0;
}

input,
select,
textarea {
  border: 1px solid var(--color-bg2);
  border-radius: var(--border-radius);
  margin-bottom: 1rem;
  padding: 0.4rem 0.8rem;
}

input[type="text"],
input[type="password"],
textarea {
  width: calc(100% - 1.6rem);
}

input[readonly],
textarea[readonly] {
  background-color: var(--color-bg2);
}

label {
  font-weight: bold;
  margin-bottom: 0.2rem;
}

/* Popups */
dialog {
  border: 1px solid var(--color-bg2);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow) var(--color-shadow);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  z-index: 999;
}

/* Tables */
table {
  border: 1px solid var(--color-bg2);
  border-radius: var(--border-radius);
  border-spacing: 0;
  max-width: 100%;
  overflow-x: auto;
  margin: 1em 0;
  white-space: normal;
}

table td,
table th,
table tr {
  padding: 0.4rem 0.8rem;
  text-align: var(--justify-important);
}

table thead {
  background-color: var(--color-fdn1);
  border-collapse: collapse;
  border-radius: var(--border-radius);
  color: var(--color-secondary);
  margin: 0;
  padding: 0;
}

table thead tr:first-child th:first-child {
  border-top-left-radius: var(--border-radius);
}

table thead tr:first-child th:last-child {
  border-top-right-radius: var(--border-radius);
}

table thead th:first-child,
table tr td:first-child {
  text-align: var(--justify-normal);
}

table tr:nth-child(even) {
  background-color: var(--color-shadow);
}

/* Quotes */
blockquote {
  display: block;
  padding: .5rem 1rem;
  border-left: 5px solid var(--color-bg2);
  border-right: 5px solid var(--color-bg2);
  background:var(--color-fdn3);
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

blockquote {
  > :last-child {
    margin-bottom: 0;
  }
}

blockquote footer {
  display: block;
  padding: 1rem 0;
}
