/* Nav */

nav#menu > li:first-child {
    line-height: 0;
    font-size: 0;
    color: transparent;
  }
  
  .icon-home {
    background: url('/assets/img/icon-home.png');
    width: 19px;
    height: 17px;
    display: block;
    line-height: 20px;
  }
  
  nav {
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0;
  }
  
  .nav > li > a,.nav-top > li > a {
    position: relative;
    display: block;
    padding:1em;
  }

  .nav>li {
    position:relative;
  }

  .nav>li>a:focus,
  .nav>li>a:hover {
    text-decoration:none;
  }


  nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  nav ul li {
    display: inline-block;
    margin: 0;
    position: relative;
    text-align: left;
    text-decoration: none;
  }
  
  nav ul li a {
    display: inline-block;
    position: relative;
    text-decoration: none;
    padding: 0 0.5rem;
  }

  nav  li > a:hover,
  nav  li > a:active,
  nav  li > a:focus {
      color: var(--color-over) !important;
      background-color: var(--color-fdn2) !important;
      text-decoration: none;
  }

  /* Nav Dropdown */
  nav ul li:hover ul,
  nav ul li:focus-within ul {
    display: block;
  }
  
  nav ul li ul {
    background: var(--color-bg2);
    border: 1px solid var(--color-fdn2);
    display: none;
    height: auto;
    position: absolute;
    white-space: nowrap;
    width: auto;
    z-index: 1;
  }
  
  nav ul li ul::before {
    /* fill gap above to make mousing over them easier */
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: -0.5rem;
    height: 0.5rem;
  }
  
  nav ul li ul li,
  nav ul li ul li a {
    display: block;
    padding: 3.5px 10px;
    clear: both;
  }

  .caret {
    display:inline-block;
    width:0;
    height:0;
    margin-left: .3em;
    vertical-align:middle;
    border-top:4px dashed;
    border-right:4px solid transparent;
    border-left:4px solid transparent
  }
