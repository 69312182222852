#nav-toggle {
  display: none;
}

@media only screen and (min-width: 1024px) {
  #nav-toggle + label {
    display: none;
  }

}

@media only screen and (max-width: 1024px) {
  .logo-fdn {
    display: none;
  }

  nav ul li ul li, nav ul li ul li a {
    display: none;
  }
  .caret {
    display: none;
  }

  #nav-top {
    text-align: left;
  }

  #nav-toggle + label {
    top: 0;
  }

  #nav-toggle:not(:checked) ~ .site-nav,
  #nav-toggle:not(:checked) ~ #nav-top {
    display: none;
  }

  /* Style pour le label qui affichera le commutateur avec icône burger */
  label[for="nav-toggle"] {
    display: block;
    width: 100%;
    padding: 10px;
    cursor: pointer;
    background-color: var(--color-bg2);
    transition: background-color 0.1s ease, color 0.1s ease;
  }

  label[for="nav-toggle"] svg,
  label[for="nav-toggle"] img {
    vertical-align: middle;
    padding: 0 0.5em;
  }

  /* Surbrillance temporaire lors de l'interaction */
  label[for="nav-toggle"]:hover,
  label[for="nav-toggle"]:active,
  #nav-toggle:focus-visible + label {
    color: var(--color-over) !important; /* Couleur de surbrillance */
    background-color: var(--color-fdn2) !important; /* Fond de surbrillance */
  }

  /* Réinitialisation de l'apparence lorsque la checkbox est cochée */
  #nav-toggle:checked + label {
    color: var(--color-initial-text); /* Réinitialisation à la couleur initiale */
    background-color: var(--color-bg2); /* Réinitialisation à la couleur de fond initiale */
  }
}
